// FONTS NEW UI-KIT
$font-sizes: (
  tiny: 10px, 
  extra-small: 12px, 
  small: 14px, 
  medium: 16px, 
  large: 18px, 
  extra-large: 20px, 
  huge: 22px, 
  giant: 24px
);

$font-weights: (
  light:300,
  regular: 400, 
  medium-weight: 500, 
  semi-bold: 600, 
  bold: 700
);

@each $name, $size in $font-sizes {
  .n-font-#{$name} {
    font-size: $size;
    line-height: $size * 1.2;
  }
}

@each $name, $size in $font-weights{
  .n-font-#{$name} {
    font-weight: $size;
  }
}

.n-uppercase {
  text-transform: uppercase;
}

.n-lowercase {
  text-transform: lowercase;
}

.n-capitalize {
  text-transform: capitalize;
}

.n-text-center {
  text-align: center;
}

.n-text-left {
  text-align: left;
}

.n-text-right {
  text-align: right;
}