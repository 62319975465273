@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.steps-form {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;

  @include for-size(phone) {
    width: 320px;
  }
}

.steps-wrapper {
  padding-top: 35px;
  padding-bottom: 50px;
}

.steps {
  display: flex;
  justify-content: space-between;
  position: relative;

  &::after {
    content: ' ';
    display: block;
    width: 100%;
    height: 0;
    position: absolute;
    top: calc(50% - 10px);
    transform: translateY(-50%);
    border-bottom: 1px dashed map-get($color-list, "grey-50");
    z-index: 0;
  }
}

.steps-many {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .steps__item,
  .steps__item-last {
    width: 10%;
    padding: 10px 10px 10px 0;
    .steps__circle {
      margin: 0;
    }
  }

  .steps__item {
    .steps__circle {
      position: relative;
      z-index: 1;
    }

    &::after {
      content: ' ';
      display: block;
      width: calc(100% - 40px);
      height: 0;
      position: absolute;
      top: 50%;
      left: 35px;
      transform: translateY(-50%);
      border-bottom: 1px dashed map-get($color-list, "grey-50");;
      z-index: 0;
    }
  }
}

.steps__item,
.steps__item-last {
  button {
    background: none;
    border: none;

    &:focus {
      border: none;
      outline: none;
    }
  }

  text-align: center;
  position: relative;
  padding: 9px;
  background-color: $main-white;
  z-index: 1;

  &.active {
    .steps__circle {
      background-color: map-get($color-list, "grey-50");
    }
  
    .steps__text {
      color: $button-step;
    }
  }
}

.steps__item-link {
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.setup {
  .steps__item:nth-child(1) {
    .steps__circle {
      background-color: map-get($color-list, "purple-100");
      animation: pulse 2s infinite;
    }
  }
}

.questions {
  .steps__item:nth-child(2) {
    .steps__circle {
      background-color: map-get($color-list, "purple-100");
      animation: pulse 2s infinite;
    }
  }
}

.templates {
  .steps__item:nth-child(3) {
    .steps__circle {
      background-color: map-get($color-list, "purple-100");
      animation: pulse 2s infinite;
    }
  }
}

.preview {
  .steps__item:nth-child(4) {
    .steps__circle {
      background-color: map-get($color-list, "purple-100");
      animation: pulse 2s infinite;
    }
  }
}

.steps__link {
  display: inline-block;
  &.disabled {
    pointer-events: none;
  }
}

.steps__circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  background-color: map-get($color-list, "purple-100");
  border-radius: 50%;
  margin: 0 auto 10px;
}

.setup,
.questions,
.templates,
.preview {
  .steps__circle {
    background-color: map-get($color-list, "purple-100")
  }

  .disabled {
    .steps__circle {
      background-color: $secondary-brand;
    }
  }

  .enabled {
    .steps__circle {
      background-color: map-get($color-list, "purple-100")
    }
  }
}

.steps__text {
  text-transform: uppercase;
  text-align: center;
  color: map-get($color-list, "purple-100");
  font-size: map-get($font-sizes, "extra-small");
  font-weight: map-get($font-weights, "bold");
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($main-active, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba($main-active, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba($main-active, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($main-active, 0.4);
    box-shadow: 0 0 0 0 rgba($main-active, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba($main-active, 0);
      box-shadow: 0 0 0 10px rgba($main-active, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba($main-active, 0);
      box-shadow: 0 0 0 0 rgba($main-active, 0);
  }
}