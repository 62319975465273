@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.candidates__list-column {
  a:hover {
    text-decoration: none;
  }
}

.candidates__list-item {
  margin-bottom: 10px;
  padding: 16px 12px;
  background-color: $header-main;
  box-shadow: 0 1px 6px 0 rgba($grey-800,0.08);
  border-left: 2px solid $main-black;
  position: relative;
  border-radius: 0px 25px 25px 0px;
  display: flex;
  cursor: pointer;

  &.invited {
    border-color: $violet-100;
  }

  &.to_review {
    border-color: $blue-100;
  }

  &.accepted {
    border-color: $main-green;
  }

  &.rejected {
    border-color: $main-red;
  }

  &.new-interview {
    border-left-width: 10px;
  }

  & .expired {
    color: $red-100;
    font-style: italic;
  }

  &[disabled] {
    pointer-events: none;
  }
}

.candidates__list-item-container {
  width: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.candidates__list-item-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  justify-content: space-between;
  color: #2E384D;

  &.button__without-styles {
    text-align: left;
  }
}

.candidates__list-item-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.candidates__list-item-submited {

  svg {
    margin: 0 5px 2px 0;
  }
}

.candidates__list-item-rating {
  @include align-left-center;
  flex-wrap: wrap;
  gap: 6px;
  cursor: pointer;

  .strong-no {
    cursor: pointer;

    g path {
      fill: map-get($color-list, 'grey-30');
    }

    &.active, &:hover {
      background-color: transparent;
      border-radius: 100%;
    }

    &.active g path, &:hover g path {
      fill: #e11f1f;
    }
  }

  .strong-yes {
    cursor:pointer;

    g path {
      fill: map-get($color-list, 'grey-30');
    }

    &.active, &:hover {
      background-color: transparent;
      border-radius: 100%;
    }

    &.active g path, &:hover g path{
      fill: #13ac00;
    }
  }
}

.candidates__list-item-rating-icons {
  line-height: 12px;
  margin-top: -4px;
}

.candidates__list-item-rating-text {
  margin-left: 4px;
  line-height: 1;
}

.candidates__list-item-avatar {
  object-fit: cover;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  min-width: 45px;
  margin-right: 12px;

  .hover {
    display: none;
  }

  &.cursor {
    cursor: pointer;
  }
}

.candidates__list-item-avatar-container {
  .candidates__list-item-avatar {
    margin-right: 0;
  }

  .hover {
    display: none;
  }

  &.with-hover:hover {
    .candidates__list-item-avatar:first-child {
      display: none;
    }

    .hover {
      display: flex;
    }
  }
}

.candidates__list-item-empty-avatar {
  background-color: map-get($color-list, "grey-30");
  display: flex;
  justify-content: center;
  align-items: center;

  &.invited {
    background-color: rgba($main-blue, 0.2);
  }

  &.to_review {
    background-color: rgba($main-purple, 0.2);
  }

  &.accepted {
    background-color: rgba($main-green, 0.2);
  }

  &.rejected {
    background-color: rgba($main-red, 0.2);
  }
}

.n-grey-400 {
  color: $grey-400;
}