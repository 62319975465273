@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.add-new-user {
  position: relative;

  &__input {
    @include align-center;
    margin-bottom: 8px;
    border-radius: 20px;
    position: relative;
  }

  &__input-field {
    width: 100%;
    padding: 12px 120px 12px 16px;
    border-radius: 20px;
    border: none;
    border: 1px solid map-get($color-list, "grey-50");

    &::placeholder {
      color:$grey-500;
    }

    &:focus {
      outline: none;
      border-color: map-get($color-list, "purple-100");
    }
  }

  &__error {
    display: block;
    margin-bottom: 8px;
    padding: 0 16px;
  }

  &__btn {
    // @include align-center-center;
    // height: 30px;
    // padding-left: 16px;
    // padding-right: 16px; 
    // color: $main-white;
    // border-radius: 15px;
    // background-color: $blue-500;
    position: absolute;
    right: 5px;

    &:disabled {
      background-color: map-get($color-list, "grey-100");
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}

.add-new-user__radio {
  padding: 0;

  .icon {
    margin-left: 2px;
    margin-bottom: 4px;
  }

  .form-group {
    margin: 0;
    padding: 0 10px;

    @include align-left-center;
    .form-check {
      display: inline-block;
      position: relative;
      padding: 0 6px;
    }
    
    .form-check input[type='radio'] {
      display: none;
    }
    
    .form-check label {
      font-size: 14px;
      line-height: 16px;
    }
    
    .form-check label:before {
      content: " ";
      display: inline-block;
      position: relative;
      top: 3px;
      margin: 0 8px 0 0;
      width: 16px;
      height: 16px;
      border-radius: 11px;
      border: 1px solid $grey-600;
      background-color: transparent;
    }
    
    .form-check input[type=radio]:checked + label:after {
      border-radius: 11px;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 7px;
      left: 9px;
      content: " ";
      display: block;
      background: $grey-600;
    }
  }
}
