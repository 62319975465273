@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.error-container {
  padding: 20px;

  &__row {
    margin-top: 15px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;

    button {
      margin-top: 20px;
    }
  }
}