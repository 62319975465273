@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';


.steps-wrapper {
  &.question {
    padding: 90px 0;

    &.idv {
      padding-bottom: 0;
    }

    @include for-size(tablet) {
      padding: 45px 0 90px;
    }

    .steps::after {
      top: 24px;
    }
  }
}

.steps__circle {
  color: #fff;
  background-color: map-get($color-list, "grey-50");

  &.small {
    width: 30px;
    height: 30px;
    margin-bottom: 0;
  }

  &.saved {
    background-color: $main-link;
  }

  &.active {
    background-color: $main-link;
    animation: pulse 2s infinite;
  }
}

.steps-questions {
  &.small {
    &::after {
      top: 50%;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($main-link, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba($main-link, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba($main-link, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($main-link, 0.4);
    box-shadow: 0 0 0 0 rgba($main-link, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba($main-link, 0);
      box-shadow: 0 0 0 10px rgba($main-link, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba($main-link, 0);
      box-shadow: 0 0 0 0 rgba($main-link, 0);
  }
}
