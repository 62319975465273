@import "./abstracts/_mixins";
@import "./abstracts/_colors";

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}

body,
#root {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1050;

  @include for-size(phone) {
    .navbar {
      justify-content: center;
      .navbar-brand {
        margin: 0;
      }

      .navbar-toggler {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }

  &__logo {
    width: 43px;
    height: 35px;
  }

  &__custom_logo {
    width: auto;
    height: 40px;
  }
}

.main-text {
  margin-bottom: 25px;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}

.main-link {
  text-decoration: underline;

  &:hover {
    text-decoration: none!important;
  }

  &__without-decoration {
    text-decoration: none!important;
  }
}

.change-browser__wrapper {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

.change-browser__image {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.users,
.jobs,
.companies {
  padding-top: 90px;
  padding-bottom: 90px;
}
.people {
  padding-top: 53px;
  padding-bottom: 90px;
}

.users {
  @media (min-width: 992px) {
    min-height: calc(100vh - 180px);
  }
}

.jobs {
  &__create-locked {
    align-self: start;
  }
  @include for-size(phone) {
    padding-top: 45px;
  }
}

button:disabled {
  cursor: default !important;
}

h1,
h2,
h3 {
  font-weight: 400;
}

h1 {
  font-size: 36px;
  line-height: 52px;
}

h2 {
  font-size: 20px;
  line-height: 24px;
}

h3 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
}

.react-notification-root {
  z-index: 3;
}
.danger {
  color: $main-red;
}

.bg-danger {
  background-color: $main-red;
}

/* If you haven't already defined this utility class */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}