@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.form {
  max-width: 350px;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;

  &__title {
    margin-bottom: 25px;

    &_main {
      margin-bottom: 10px;
    }

    &_error {
      margin-bottom: 5px;
    }
  }

  &__title-with-error {
    margin-bottom: 25px;
  }

  &__link {
    margin-bottom: 15px;
  }

  &__search-input {
    width: 100%;

    &:focus {
      outline: none;
      border: 1px solid map-get($color-list, "purple-100")
    }

    &.jobs {
      margin-bottom: 22px;
    }
  }
}


@include for-size(phone) {
  .form {
    padding-top: 10px;

    &__title {
      margin-bottom: 10px;
    }

    &__link {
      margin-bottom: 5px;
    }
  }
}

.label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-element {
    display: flex;
    flex-direction: row;
    margin-right: 16px;
  }
}

.label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px!important;
  padding: 0px 0px 0px 16px ;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &__question {
    padding: 50px 16px 0;
  }

  &__texteditor {
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
  }

  &__text {
    margin-right: 5px;
  }

  &__left {
    @include align-left-center;
  }

  &__main {
    @include align-center;
  }

  &__secondary {
    color: $label-secondary;
  }

  &__bottom {
    display: block;
    padding: 8px 16px;
  }
}