@import '~assets/styles/abstracts/colors';

.breadcrumbs {
  padding-top: 20px;

  @include for-size(phone) {
    padding-top: 50px;
  }

  &__list {
    padding-top: 30px;
    display: flex;
    align-items: center;

    .breadcrumbs__item:last-child {
      font-weight: map-get($font-weights, "semi-bold");
    }

    .breadcrumbs__item {
      max-width: 40%;
      overflow:hidden; 
      white-space:nowrap; 
      text-overflow: ellipsis;
    }
    span {
      padding: 0 5px;
    }
  }

  &__item,
  &__active {
    padding: 0;
  }

  &__link {
    &.disabled {
      pointer-events: none;
    }

    &:hover {
      text-decoration: none;
      color: map-get($color-list, "black-100")
    }
  }
}