@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';


.change-browser__change-browser {
    width: 100%;
    height: 100%;

    @include align-center;
    flex-wrap: wrap;
}

.change-browser__header,
.change-browser__main,
.change-browser__footer {
    width: 100%;
    text-align: center;
}

.change-browser__main,
.change-browser__footer {
    * {
        @include font-size(18);
    }
}

.change-browser__main {
}

.change-browser__footer {
    padding: 30px 0 50px;
    @include align-center;

    .button__large {
        @include align-center;
        width: 285px;
    }
}

.change-browser__block {
    @include align-center-top;
}

.change-browser__title  {
    margin-top: 20px;
    margin-bottom: 20px;
    @include font-size(36);
    color: $grey-300;

    @include for-size(phone) {
      @include font-size(24)
    }
}

.change-browser__text {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    @include font-size(18);
}

.change-browser__text-block {
    display:flex;
    min-height: 20px;
    justify-content: center;
    align-items:center;

    &:after{
        content:'';
        min-height:inherit;
        font-size:0;
    }
}

.change-browser__icon-block {
    @include align-center;
    flex-wrap: wrap;
    width: 80px;
    margin: 60px 20px 0 20px;
}

.change-browser__icon,
.change-browser__icon-caption {
    width: 100%;
}

.change-browser__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

.change-browser__link,
.change-browser__link-button {
    text-decoration: underline;
    text-align: center;

    &:hover {
        text-decoration: none;
    }

    &.white {
        text-decoration: none;
        color: $main-white;

        &:hover {
            text-decoration: none;
            color: $main-white;
        }
    }
}

.change-browser__link {
    display: inline-block;
    max-width: 300px;
    width: 100%;
    margin-right: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.change-browser__text {
    margin-bottom: 10px;
}
