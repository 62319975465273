@import "~assets/styles/abstracts/colors";

.countdown-timer {
  background: rgba(77, 85, 107, 0.25);
  padding: 3px 11px;
  border-radius: 20px;
  color: $main-white;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.n-bg-red-100 {
    color: $main-white;

    span {
      animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }
}
