@import '~assets/styles/abstracts/mixins';

.social-login {
  margin-bottom: 50px;
  text-align: center;

  &__title,
  &__sup-title {
    margin-bottom: 10px;
  }

  @include for-size(phone) {
    margin-bottom: 20px;
  }
}