@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.billing-card {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 0 7px $card-shadow;
  overflow: hidden;

  & > div {
    width: 100%;
  }

  &__title,
  &__list {
    padding: 0 10px;
  }

  &__title {
    @include align-center;
    height: 60px;
    position: relative;

    h2 {
      font-size: 16px;	
      letter-spacing: 1.76px;
      line-height: 24px;
      text-transform: uppercase;
      color: $secondary-purple;
    }

    span {
      display: block;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      text-transform: initial;
    }

    
  }

  &__title-divider {
    width: 40px;
    height: 2px;
    margin-top: 10px;
    background-color: #49C5EB;
    position: absolute;
    right: auto;
    left: auto;
    bottom: 15px;
  }

  &__list {
    margin-bottom: 40px;
    text-align: left;

    li {
      @include align-left-center;
      padding: 7px !important;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      color: $text-black;

      svg {
        margin-right: 7px;
      }
    }
  }

  &__footer {
    padding: 15px 0;
    text-align: center;
    align-self: flex-end;
    background: $card-gradient; 

    &_text {
      font-size: 20px;
      letter-spacing: 2.25px;
      line-height: 24px;
      color: $main-white;
      text-transform: uppercase;


      .price,
      .period {
        padding: 0 3px;
        letter-spacing: 1px;
        text-transform: initial;
      }

      .price {
        font-size: 24px;
        font-weight: 600;	
        line-height: 28px;
      }

      .period {
        font-size: 13px;
        font-weight: 300;
        line-height: 11px;
      }
    }
  }
}