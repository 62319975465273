@import "~assets/styles/abstracts/mixins";
@import "~assets/styles/abstracts/colors";

.ranking-search {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include for-size(tablet) {
    align-items: flex-start;
    margin-bottom: 16px;
  }
}

.rating-wrapper {
  display: flex;
  padding: 6px 25px;

  @media (max-width: 912px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 475px) {
    padding: 6px;
  }
}

.ranking-search__title {
  @include align-left-center;
  margin-right: 12px;
}

.form__search_ranking {
  font-size: 0;
  line-height: 1;

  @media (max-width: 912px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
