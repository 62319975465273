@import '~assets/styles/abstracts/colors';

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 4px);
  margin-bottom: 15px;
  padding: 5px 0;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  
  &_error {
    color: $main-red;
    border: 2px solid $main-red;
    border-radius: 10px;
  }

  &_success {
    color: $main-green;
    border: 2px solid $main-green;
  }
}