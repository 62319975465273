@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.job-overview__candidate-progress-select {
  @include align-left-center;
  @include font-size(14);

  @include for-size(phone) {
    justify-content: space-between;

    .candidate-progress-select-wrapper {
      background: #EDF2F7;
      border-radius: 24px;
      padding: 0 2px;
      width: 115px;
      margin-bottom: 5px;
    }
  }
}

.candidate-progress-select-wrapper {
  width: 110px;
  margin-left: 35px;
}

.candidate-progress__send,
.candidate-progress__received {
  margin-bottom: 20px;
  padding-right: 20px;

  @include for-size(phone) {
    padding-right: 0;
  }
}

.candidate-progress__send-info,
.candidate-progress__received-info {
  padding: 15px 25px;
  background-color: $main-white;
  box-shadow: 0 1px 6px 0 rgba(98,110,130,0.08);
  border: 0.5px solid #BBC2C9;
  border-radius: 25px;

  & > div:last-child {
    margin-bottom: 0;
  }

  & .progress-line {
    &__left, &__right {
      color: $main-link !important;
    }
  }
}

.candidate-progress__received-info {
  @media (max-width: 990px) {
    .progress-line {
      flex-wrap: wrap
    }
  }
}

.candidate__spinner-wrapper {
  height: 90px;
}

.candidates__buttons {
  @include for-size(phone) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  &.status-switcher {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.candidate__button-switcher {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0;

  &.table {
    margin: 0;
    justify-content: flex-end;
    display: flex;
    padding-right: 3px;
  }
}

.candidate__button-switcher-text {
  margin-right: 16px;
}