@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.header__candidate {
  background: $header-main;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;

  &__search {
    margin: 0;
    padding: 5px 30px;
    font-size: 14px;
    line-height: 16px;
    color: $main-white;
    border-radius: 14px;
    border: 1px solid $header-search-border;
    background: $header-search-bg;



    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $main-white;
    }
  }

  .navbar-brand {
    margin: 0;
    padding-top: 0;
  }
}

.header__candidate-wrapper {
  height: 80px;
  & > div:nth-child(1) {
    @include align-left-center;
  }

  & > div:nth-child(2) {
    @include align-center;
  }
}

.header__company-info {
  @include align-left-center;
  align-items: stretch;
}

.header__company-info-block {
  @include align-vertical;
}

.header__company-info-image {
  display: flex;
  align-items: center;
  width: 40px;
  margin-right: 20px;
  border-radius: 2px;

  img {
    width: 100%;
  }
}

.header__company-block-title {
  width: 220px;
  color: $main-white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 425px) {
    width: auto;
  }
}

.header__company-block-additional-info {
  @include font-size(16);
  color: $main-white;
  white-space: nowrap;

  span {
    display: inline-block;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: 425px) {
      width: auto;
    }
  }
}

.header__company-block-icon {
  margin-right: 5px;
  margin-left: 20px;

  #Company {
    fill: $main-white;
    fill-opacity: 1;
  }
}

.header-absolute {
  position: absolute;
}
