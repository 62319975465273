@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';


.interview-complete__wrapper{
  @include align-center;
  flex: 1 1 100%;
}

.interview-complete {
  max-width: 650px;
  padding: 30px 15px;
  text-align: center;
}

.interview-error  img, .maintenance img {
  width: 100%;
  max-width: 300px;
  min-height: 300px;
}

.interview-complete__text-box, .interview-error__text-box {
  padding: 50px 0;
}

.interview-complete__title, .interview-error__title, .maintenance__title {
  margin-bottom: 20px;
  @include font-size(24, 32, 500);
  @include for-size(phone) {
    margin-bottom: 10px;
  }
}

.maintenance__title {
  @include font-size(36, 54, 600);
}

.interview-complete__job-title,
.interview-complete__company-title {
  font-weight: 600;
}

.interview-complete__company-title {
  margin-bottom: 20px;
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include for-size(phone) {
    margin-bottom: 10px;
  }
}

.interview-complete__text, .interview-error__text, .maintenance__text {
  margin-bottom: 16px;
  @include font-size(16);
  color: #34495E;

  &.refresh {
    color:$main-grey;
  }

  a.main-link__without-decoration.n-font-medium {
    color: $main-purple;
  }
}
