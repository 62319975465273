@import '~assets/styles/abstracts/mixins';

.rc-steps-item-container {
  width: 100%;
}

.rc-steps-item-title {
  margin: 0;
  @include font-size(14,22);
  color: #1E384B !important;
  font-weight: 500;
  width: 100%;
}

.rc-steps-item-description {
  padding-bottom: 35px !important;
  @include font-size(12,22);
  color: #768493 !important;
}

.rc-steps-vertical {
  margin-left: 15px;
  .rc-steps-item-tail {
    height: calc(100% - 4px);
    padding: 22px 0 0px 0;
    top: 5px;
  }
  .rc-steps-item-process .rc-steps-item-tail{
    padding-top: 15px;
    top: 5px;
  }
  .rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
    border: none;
  }
}

.rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon {
  @include align-center;
  margin-right: 8px;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  border: 1px solid #ccc;

  svg {
    height: 12px;
  }
}
