@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 2px);
  margin-bottom: 15px;
  padding: 12px 15px;
  border-radius: 10px;
  text-align: center;
  @include font-size(16, 24, 600);
  
  &_error {
    color: $main-red;
    border: 1px solid $main-red;
    border-radius: 10px;
  }
  
  &_success {
    color: $main-green;
    border: 1px solid $main-green;
  }
  
  &__icon {
    margin-right: 15px;
  }
}