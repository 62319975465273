@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.comments {
  margin: -25px 0 40px;
  @media (max-width: 767px) {
    margin: 40px 0;
  }
}

.commtents__title {
  margin-bottom: 20px;
  @include font-size(14,16,600);
  color: $text-black;
}

.commtents__list {
  padding: 20px 25px;
  border-radius: 2px;
  box-shadow: 
    1px 1px 6px 0 rgba($comments-grey, 0.11),
    0 0 25px 0 rgba($comments-grey, 0.2);
  background-color: $main-white;
}