@import '~assets/styles/abstracts/colors';

#color-picker {
  color: rgba($main-grey,0.5);
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 3;
}

.twitter-picker {
  &.custom {
    position: absolute !important;
    z-index: 4;
  }
}

.color-picker-wrapper {
  position: relative;
}

.color-picker-current-color {
  display: block;
  width: 28px;
  height: 28px;
  margin: 7px;
  border-radius: 50%;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
}

.color-picker-current-color,
.input-element {
  &.disabled {
    opacity: 0.4;
  }
}