@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.dropdown-menu {
  padding-top: 16px;
  padding-bottom: 16px;

  width: 100vw;
  max-width: 250px;

  &.dropdown-menu-right {
    min-width: 140px;
    margin-top: 5px;
    border-radius: 20px;
    border: none !important;
    background-color: map-get($color-list, "white");
    box-shadow: 0 0 5px map-get($color-list, "grey-50");
    right: -13px;
  }

  .dropdown-divider {
    margin: 8px 24px;
    border-top: 1px solid map-get($color-list, "black-50");
  }

  .dropdown-item {
    padding: 0;
    button, span {
      display: block;
      width: 100%;
      padding: 8px 24px;
      font-size: map-get($font-sizes, "small");
      line-height: map-get($font-sizes, "small") * 1.2;
      text-align: center;
      font-weight: 400;
    }

    &_bold {
      font-weight: 600;
    }

    &:hover {
      background: $header-meud-dropdwon-hover;
    }
  }
}

.dropdown-toggle {
  display: flex;
  flex-wrap: wrap;

  .dropdown-menu__title,
  .dropdown-menu__subtitle {
    padding-left: 25px;
  }

  .dropdown-menu__title-text,
  .dropdown-menu__subtitle-text {
    display: block;
   
    color: $main-link;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .dropdown-menu__title-text {
    width: 155px;
  }

  .dropdown-menu__subtitle-text {
    width: 140px;
  }

  .dropdown-menu__title {
    margin-bottom: 5px;
  }

  .dropdown-menu__subtitle {
    display: flex;
    align-items: center;
    color: $grey-50;
    @include font-size(12);
  }

  .dropdown-menu__subtitle-icon {
    width: 7px;
    margin-right: 7px;
    flex-shrink: 0;

    * {
      fill: $main-link;
    }
  }

  &::after {
    border-top-color: $main-link !important;
    position: absolute;
    right: 10px;
    top: 5px;
    content: none;
  }
}

.dropdown-menu__content {
  display: flex;
}
