@mixin for-size($class) {
  @if $class == phone {
    @media (max-width: 430px) { @content; }
  }

  @else if $class == tablet {
    @media (max-width: 769px) { @content; }
  }
 
  @else if $class == desktop {
    @media (max-width: 992px) { @content; }
  }
 
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

@mixin align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin align-top {
  display: flex;
  align-items: flex-start;
}
@mixin align-center-top {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@mixin align-center-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin align-bottom-center {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@mixin align-right-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin align-left-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


@mixin align-left-top {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin align-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin center-children($child) {
  text-align: center;
  &:before {
    content: '\200B';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .#{$child} {
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin font-size($size, $line-height:($size * 1.3), $weight: 400) {
  font-size: $size + px;
  line-height: $line-height + px;
  font-weight: $weight;
}

$base-header-font-size: 28px;

@mixin generate-headers-with-children() {
  @for $i from 1 through 6 {
    h#{$i} {
      font-size: ($base-header-font-size - (4 * $i)) !important;
      line-height: ($base-header-font-size - (4 * $i)) * 1.2 !important;
      * {
        font-size: ($base-header-font-size - (4 * $i)) !important;
        line-height: ($base-header-font-size - (4 * $i)) * 1.2 !important;
      }
    }
  }
}
