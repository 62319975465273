@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.connect__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0;
  padding: 15px 25px;
  min-height: 120px;
  border-radius: 20px;
  box-shadow: 
    1px 1px 6px 0 rgba(98,110,130,0.11),
    0 0 25px 0 rgba(98,110,130,0.2);

  &.lever {
    .lever-button {
      min-width: 160px;
      margin-bottom: 10px;
    }
    .lever-label {
      margin: 10px 0 5px 15px;
    }
    .col-md-2 {
      padding-left: 0;
    }
    .css-0 {
      text-align: center;
    }
    .row {
      justify-content: space-between;
      align-items: center;
      margin: 0 0 10px;
      .lever-button {
        margin-bottom: 0;
        min-width: auto;
        align-self: end;
      }
      div .multi-select {
        width: 300px;
      }
    }
    .lever-triggers {
      justify-content: initial;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 19px;
    }
    .selected-trigger {
      background: rgba(90, 42, 241, 0.1);
      border-radius: 20px;
      padding: 7px 13px;
      margin-left: 15px;
      svg {
        margin-top: 5px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
    .lever-connected {
      background: #C1F0C0;
      border-radius: 12.5px;
      padding: 6px 12px;
    }
  }

  &.idv {
    .progress__pannel.checks {
      position: relative;

      .profile__title-progress {
        margin-top: 10px;
      }
    }

    .idv-header {
      margin: 0;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      @media (max-width: 640px) {
        justify-content: flex-start;
        flex-direction: column-reverse;
        align-items: flex-end;

        h1 {
          width: 100%;
        }
      }
    }

    .idv-connected {
      padding: 6px 12px;
      border-radius: 12.5px;

      @media (max-width: 640px) {
        margin-bottom: 5px;
      }
    }

    .idv-purchase-btn {
      margin-bottom: 10px;
    }
  }
}

.connect {
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;

  &__title {
    margin-bottom: 15px;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__label {
    margin-bottom: 10px;
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__image-lever {
    width: 160px;
    height: 66px;
    margin: -10px;
  }

  &__input {
    text-decoration: none !important;
    color: #768493 !important;
    font-size: 14px !important;
  }

  &__description {
    text-align: center;
    margin-bottom: 15px;
  }
}

.green-30 {
  background-color: map-get($color-list, "green-30");
}

.n-purple-100 {
  color: $purple-100;
}

.idv-connect-modal {
  height: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  min-width: 850px;

  

  .modal-content {
    border-radius: 20px;

    button.close {
      padding: 10px;
    }

    .modal-body {
      overflow-y: auto;
      max-height: 550px;
      margin-bottom: 10px;

      &::-webkit-scrollbar {
        border: none;
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: $main-white;
        border-radius: 5px;
        margin-left: -5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #C5C4C4;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $purple-100;
      }
    }

    @media (max-height: 1077px) {
      top: 20px;
    }

    @media (max-height: 800px) {
      top: 35px;
    }

    @media (max-width: 768px) {
      margin: 20px;
    }

    @media (max-width: 640px) {
      max-height: 510px;
      border-radius: 15px;
    }
  }

  @media (max-width: 768px) {
    min-width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-title {
      font-size: 28px;
      line-height: 36px;
      font-weight: 500;
      color: #5A2AEF;

      @media (max-width: 640px) {
        margin-top: 10px;
        font-size: 24px;
      }
    }

    &-subtitle {
      padding: 0 100px;
      margin-top: 17.04px;
      margin-bottom: 17.04px;

      @media (max-width: 640px) {
        font-size: 14px;
        padding: 0;
        margin: 10px 0;
      }
    }
  }
  &__footer {
    &-subtitle{
      padding: 0px 10px;
      margin-top: 20px;
      color: #595959;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      flex-direction: row;
      gap: 7px;
      align-items: center;
      display: flex;
      line-height: 21px;
    }

    &-icon{
      cursor: pointer;
    }
  }

  &__plans {
    margin: 0;
    margin-bottom: 18px;

    &-col {
      padding: 0 10px;

      @media (max-width: 768px) {
        padding: 5px 0;
      }

      &-price {
        color: #5A2AEF !important;

        @media (max-width: 640px) {
          font-size: 26px;
          line-height: 24px;
        }
      }

      .wrap-button {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 640px) {
          margin-top: 10px;
        }

        .idv-buy-btn {
          width: 100%;

          @media (max-width: 640px) {
            padding: 10px 24px;
          }
        }

        .idv-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 768px) {
            flex:1;
          }
    
        }
      }
    }

    &-item {
      border: 1px solid #d8d8d8;
      border-radius: 20px;
      padding: 17px 15px;
      position: relative;
      margin: 0 auto;
      height: 100%;

      @media (max-width: 640px) {
        padding: 10px 12px;

        h3 {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }
  }

  &__subscribe {
    padding: 10px;

    .plans__pro {
      margin-bottom: 0;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 162px;
      }

      &-payg {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .error-container {
        width: 100%;
        justify-content: end;
      }
    }

    @media (max-width: 768px) {
      padding: 0;
      padding-top: 10px;
    }

    @media (max-width: 640px) {
      .plans__pro {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;

        &-title {
          font-size: 18px;
          line-height: 32px;
        }

        &-desc {
          margin-bottom: 10px;
        }

        button {
          padding: 10px 24px;
          width: 100%;
        }
      }
    }
  }

  .idv-modal-price {
    align-items: flex-end;
  }

  .idv-modal-price-save {
    font-weight: 500;
  }

  .error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 10px;

    svg {
      margin-right: 5px;
    }
  }
}

.fade.modal.show {
  & > .header {
    z-index: 999;
  }
}

.n-button__small.idv-btn-disabled {
  cursor: not-allowed !important;
}