@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.candidate-details {
  .modal-body {
    padding: 30px 50px;
  }
}

.form__candidate-details-title {
  margin-bottom: 40px;
  @include font-size(22);
  text-align: center;
  color:#34495E;
}

.form__candidate-details-subtitle {
  margin-bottom: 40px;
  @include font-size(16);
  text-align: center;
}

.form__candidate-details-button {
  display: flex;
  justify-content:center;
  padding: 25px 0;

  .button {
    max-width: 180px;
    font-size: 22px;
  }
}