.modal-dialog {
  margin: .5rem auto;
  
  &.confirm {
    margin-top: 200px;

    .confirm__icon {
      margin-bottom: 20px;
    }

    .modal-content {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      border-radius: 20px;
    }
    
    .modal-header,
    .modal-footer {
      border: 0; 
      .button__group {
        width: 100%;
      }
    }

    .modal-header {
      padding-top: 24px !important;
      padding-bottom: 16px !important;
      position: relative;

      h4 {
        width: 100%;
        text-align: center !important;
        margin-bottom: 0;
      }

      .close {
        margin: 0;
        padding: 0;
        width: 16px;
        height: 16px;
        line-height: 16px;
        position: absolute;
        top: 22px;
        right: 20px;

        &:focus {
          outline: none;
        }
      }
    }

    .modal-body {
      padding: 0 24px !important;
      font-size: map-get($font-sizes, "small");
      line-height: map-get($font-sizes, "small") * 1.2;
      color:  map-get($color-list, "grey-100");
    }

    .modal-footer {
      padding: 16px 24px 24px 24px !important;
    }
  }
}