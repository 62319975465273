@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.input__rating {
  @include align-left-center;
  display: inline-flex;
  margin: 0 5px 0 0;

  @include for-size(phone) {
    margin-bottom: 12px;
  }
}

.input__rating-item:checked,
.input__rating-item:not(:checked) {
  position: absolute;
  left: -9999px;
}
.input__rating-item:checked + label,
.input__rating-item:not(:checked) + label {
  display: inline-block;
  margin: 0;
  padding: 8px 11px;
  
  position: relative;
  cursor: pointer;
  line-height: 1;
}

.input__rating-item:checked + label {
    border-color: $main-active;
}


.input__rating-label {
  border-radius: 20px;
  padding: 8px 16px;
  background-color: #FFFFFF;
}
