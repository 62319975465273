.select__menu-list {
  .select__option--is-focused, 
  .select__option--is-selected {
    background-color: transparent;
    color: $main-black;
  }

  .select__option:hover {
    background-color: #DEEBFF;
  }
}