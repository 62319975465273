@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.progress-line {
  margin-bottom: 20px;
  @include align-center-center;
}

.progress-line__progress {
  width: 100%;
  margin: 0 15px;

  &.progress {
    height: 6px;

    &.purple {
      .progress-bar {
        background-color: $cadnidates-blue; 
      }
    }

    &.blue {
      .progress-bar {
        background-color: #5BC1E6 
      }
    }

    &.green {
      .progress-bar {
        background-color: #81D77F; 
      }
    }
    
    &.red {
      .progress-bar {
        background-color: #FE5759; 
      }
    }
  }
}

.progress-line__left {
  color: #8798AD;
}

.progress-line__left,
.progress-line__right {
  @include font-size(14);

  &.sm {
    flex-basis: 10%;
  }

  &.md {
    flex-basis: 20%;
  }

  &.lg {
    flex-basis: 30%;
  }

  &.x-lg {
    flex-basis: 40%;
  }

  &.half {
    flex-basis: 50%;
  }
}

.progress-line__left {
  flex-shrink: 0;
}
