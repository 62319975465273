@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.credit-cards {
  @include align-center;
  max-width: 190px;
  padding: 0 20px;
  text-align: left;

  .ElementsApp, .ElementsApp .InputElement {
    font-size: 12px;
  }

  &__title {
    display: inline-block;
    margin-right: 10px;
    @include font-size(12, 14, 300);  
  }

  &__icon {
    width: 26px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.with-bg {
    background: $grey-100;
  }

  &.small {
    width: 100%;
    padding: 5px 10px;
    margin: 0 auto 10px auto;
    background-color: $grey-100;
    border-radius: 5px;

    .credit-cards__title {
      margin-right: 10px;
      @include font-size(8, 12, 300);
    }

    .credit-cards__icon {
      width: 20px;
      height: 12px;
      margin-right: 3px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
