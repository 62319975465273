@import "~assets/styles/abstracts/mixins";
@import "~assets/styles/abstracts/colors";

.form-candidates {
  &__divider {
    @include align-center;
    margin: 30px 0 35px 0;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }

  &__divider-text {
    margin: 0 10px;
    @include font-size(18);
    color: $main-active;
  }

  &__divider-left-line,
  &__divider-right-line {
    display: block;
    width: 100%;
    height: 1px;
    background: $main-active;
  }
}

.form-candidates__block {
  margin-bottom: 40px;
  text-align: center;
}

.form-candidates__title {
  margin-bottom: 20px;
  text-align: center;
}

.form-candidates__text {
  margin-bottom: 40px;
  @include font-size(18);
}

.candidate {
  padding: 0 25px;
}

.candidate-add-btn-wrapper {
  @include align-center;
  padding: 24px 0 16px 0;
}
