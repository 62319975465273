@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.add-comment__warning-message {
  margin-bottom: 20px;
  color: $main-red;
}

.form__add-comment-textarea {
  width: 100%;
  height: 20px;
  min-height: 20px;
  max-height: 150px;
  margin: 0;
  color: $main-grey;
  @include font-size(14);
  resize: none;
  border: 1px solid map-get($color-list, "black-5");
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px 25px;
  margin-top: 20px;

  &::-webkit-input-placeholder {
    color: map-get($color-list, "black-50");
  }

  &:focus {
    outline: none;
    border-color: map-get($color-list, "purple-100");
  }

  &.with-warning-message {
    border-color: $main-red;
  }
}

.form__add-comment-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  p {
    margin-left: 15px;
  }
  button {
    font-size: 14px;
    &:disabled {
      background-color: map-get($color-list, "black-30") !important;
    }
  }
  @media (max-width: 575px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    button {
      margin-bottom: 5px;
    }
    p {
      width: 100%;
      padding-left: 15px;
    }
  }
}