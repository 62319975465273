@import "~assets/styles/abstracts/colors";
@import "~assets/styles/abstracts/mixins";

.ReactTable {
  margin-top: 40px;
  margin-bottom: 20px;
  border: none;
  box-shadow: 0 1px 6px 0 rgba(98, 110, 130, 0.08);

  .editable__input {
    background: none;
    border: none;
    border-bottom: 1px solid $grey-600;
  }


  .rt-thead {
    color: $main-white;
    background-color: #b6bdc5;

    min-width: 0px!important;
    &.-header{
      min-width: 0px!important;
    }

    .rt-th {
      padding: 12px 8px;
      font-size: map-get($font-sizes, "small");
      line-height: map-get($font-sizes, "small") * 1.2;
      font-weight: map-get($font-weights, "light");
      border-right: none;
      border-bottom: none;

      &.first-column {
        &.left {
          text-align: left;
        }
      }

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }

      &.rt-resizable-header {
        &:last-child {
          max-width: 50px !important;
        }
      }

      &.left-align-column {
        text-align: left;
      }

      &.center-align-column {
        text-align: center;
      }
    }
  }

  .rt-tbody {
    overflow-x: auto;
    position: relative;
    min-width: 0px!important;

    .rt-td {
      padding: 12px 8px;
      font-size: map-get($font-sizes, "small");
      line-height: map-get($font-sizes, "small") * 1.2;
      font-weight: map-get($font-weights, "light");
      border-right: none;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }

      &.fullname-invite {
        svg {
          margin-right: 15px;
          margin-bottom: 2px;
        }
      }

      .table-cell_disabled {
        color: $main-red;
      }

      @include for-size(tablet) {
        .table-cell_response {
          padding-left: 15px;
        }
      }

      .table-cell {
        &.with-error {
          color: $main-red;
        }
      }

      svg {
        height: 15px;
      }
    }
  }

  .rt-noData {
    top: 70%;
    background: none;

    @include for-size(phone) {
      width: 100%;
      height: 100%;
      text-align: center;
      padding-top: 25px;
      font-size: 14px;
    }
  }
}

.jobs, .people {
  &__col-center {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0;

    @media (max-width: 991px) and (min-width: 768px) {
      padding-left: 0;
      padding-right: 30px;
      margin-top: -7px;
    }

    .jobs__clear-filter {
      @media (max-width: 1199px) {
        margin-top: 0;
      }

      @media (max-width: 991px) {
        margin-top: 7px;
      }
    }
  }

  &__col-reverse {
    @include for-size(phone) {
      order: -1;
      margin-bottom: 12px;
    }
  }

  &__message {
    min-height: 30px;
    margin-bottom: 15px;
  }

  &__clear-filter {
    padding: 0;
    border: none;
    background: none;
    margin: 10px 0;

    &:focus {
      outline: none;
    }
  }

  &__cleate-button {
    min-width: 140px;

    @media (max-width: 991px) {
      min-width: 115px;
      padding: 14px 15px;
    }
  }

  &.container {
    background: $main-white;
  }

  ._space {
    background: $main-white;
    min-width: 1110px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    height: 50px;
    top: 60px;
    margin: 0 -15px;
  }

  .message {
    margin: 0;
  }

  .ReactTable {
    margin-top: -50px;

    .rt-table {
      overflow: visible;
    }

    .rt-thead {
      top: 110px;
      overflow-x: auto;
      position: sticky;
      z-index: 1;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .rt-th {
      &.rt-resizable-header {
        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3) {
          display: flex;
          justify-content: center;
          padding: 0;
        }
      }

      &:last-child {
        padding: 0;
        width: 0 !important;
      }
    }
  }
}

.people, .jobs {
  .ReactTable {
    .rt-thead {
      background: map-get($color-list, "black-5");
      border-radius: 20px 20px 0px 0px;
      box-shadow: none;
      .rt-th {
        color: #4D556B;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
      }
    }

    .rt-th:last-child {
      padding: 12px 8px;
    }
  }
}

.people .ReactTable .rt-thead .rt-th {
  &.left-align-column {
    flex-basis: 0% !important;
  }
}

@media (max-width: 1025px) {
  .jobs, .people {
    ._space {
      display: none;
    }

    .ReactTable {
      margin-top: 0;

      .rt-table {
        overflow: auto;
      }

      .rt-thead {
        position: relative;
        top: 0;
        z-index: 1;
      }
    }
  }
}
