@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.profile-card__ava {
  @include align-center;
  width: 100%;
  position: absolute;
  top: -32px;

  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid $purple-200;
    overflow: hidden;
  }
}