@import "~assets/styles/abstracts/colors";
@import "~assets/styles/abstracts/mixins";

.header {
  background: $header-main;

  &__search {
    margin: 0;
    padding: 5px 30px;
    font-size: 14px;
    line-height: 16px;
    color: $main-white;
    border-radius: 14px;
    border: 1px solid $header-search-border;
    background: $header-search-bg;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $main-white;
    }
  }

  &__upgrade-button {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .navbar {
    height: 80px;
    padding: 0;

    .navbar-nav,
    .navbar-brand {
      height: 100%;
    }

    .navbar-brand {
      @include align-left-center;
      margin-right: 5rem;

      @media (max-width: 991px) and (min-width: 768px) {
        margin-right: 3rem;
      }
    }

    .navbar-toggler {
      margin-left: auto;
    }

    .nav-item {
      padding-left: 0;
      padding-right: 40px;

      @media (max-width: 991px) {
        padding-right: 20px;
      }

      @media (max-width: 767px) {
        padding-right: 0px;
      }

      a,
      span {
        @include align-center;
        height: 100%;
        color: $main-link;
        position: relative;

        &:hover {
          text-decoration: none;
        }

        &.active {
          color: $main-active;

          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 4px;
            background: $main-active;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }

    .navbar-collapse {
      height: 100%;

      @media (max-width: 767px) {
        height: calc(100vh - 80px) !important;
        margin-left: -15px;
        margin-right: -15px;
        background: $header-main;

        @include for-size(phone) {
          height: calc(100vh - 60px) !important;
        }

        .navbar-nav {
          justify-content: center;
          position: relative;
          padding-bottom: 80px;
        }

        .nav-item a,
        .nav-item span {
          padding: 30px 0;
          font-size: 24px;
          line-height: 16px;
          cursor: pointer;
        }

        .nav-item a:after {
          display: none;
        }
      }
    }
  }
}

.logo {
  @include align-left-center;
  height: 80px;
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@include for-size(phone) {
  .header {
    .navbar {
      height: 60px;

      .navbar-brand {
        margin-left: 5rem;
      }
    }

    .navbar-collapse {
      height: calc(100vh - 60px);
    }
  }

  .logo {
    height: 60px;
  }
}

.nav-notification {
  position: relative;
  display: inline-block;
  padding: 0 !important;

  &.active span {
    color: $main-active !important;
  }
}

.nav-notification .nav-badge {
  margin: 2px 0 0 5px;
  padding: 0 2px;
  border-radius: 50%;
  background: map-get($color-list, "red-100");
  color: white;
  font-size: 11px;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 10px;
  animation: pulse-badge 4s infinite;

  @include for-size(tablet) {
    top: 10px;
  }
}

@-webkit-keyframes pulse-badge {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(233, 54, 45, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(233, 54, 45, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(233, 54, 45, 0);
  }
}
@keyframes pulse-badge {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(233, 54, 45, 0.4);
    box-shadow: 0 0 0 0 rgba(233, 54, 45, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(233, 54, 45, 0);
    box-shadow: 0 0 0 10px rgba(233, 54, 45, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(233, 54, 45, 0);
    box-shadow: 0 0 0 0 rgba(233, 54, 45, 0);
  }
}
