@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.show-form {
  margin: 0 auto;
  padding: 25px 0;
  position: relative;

  &.billing-information {
    padding: 25px 35px;
    @include for-size(tablet) {
      padding: 15px 25px;
    }
  }

  &__icon {
    margin-right: 15px;

    * {
      fill: $main-active;
    }
  }

  &__delete {
    padding: 0;
    border: none;
    background: none;
    position: absolute;
    top: 15px;
    right: 15px;

    &:focus {
      outline: none;
    }

    svg {
      margin-right: 0;
    }
  }

  &__row {
    display: flex;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
  }

  &__row_second {
    margin-bottom: 0;
    margin-left: 40px;
  }

  &__date {
    margin: 0 30px;
    @include for-size(tablet) {
      margin: 0 15px;
    }
  }
}