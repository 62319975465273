@import "~assets/styles/abstracts/mixins";
@import "~assets/styles/abstracts/colors";
@import "~assets/styles/abstracts/fonts"; // New ui-kit

.button {
  width: 100%;
  padding: 12px 10px;
  border-radius: 10px;
  text-align: center;
  border-width: 0;
  background: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    box-shadow: none;
    background: $button-disabled;
  }

  &[disabled] {
    background: $button-disabled !important;
  }

  &__menu {
    min-height: 36px;
    padding: 10px 30px !important;

    @media (max-width: 991px) {
      padding: 10px 15px !important;
      font-size: 14px !important;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &__icon-round {
    @include align-center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid $grey-400;
  }

  &__extra-small {
    width: 100%;
    max-width: 100px;
    padding: 7px 5px;
    border: 1px solid $grey-400;

    &_round {
      border-radius: 22px;
    }

    &_main {
      color: $main-white;
      background-color: $main-purple;
    }
  }

  &__small {
    width: 100%;
    max-width: 165px;
    height: 44px;
    border: 1px solid $grey-400;

    &_round {
      border-radius: 22px;
    }

    &_grey {
      color: $main-white;
      background-color: $main-grey;
    }

    &_green {
      color: $main-white;
      border-color: $cadnidates-parakeet;
      background-color: $cadnidates-parakeet;
    }
  }

  &__medium {
    max-width: 150px;
  }

  &__wrapper {
    width: 100%;
    margin: 0 5px;
  }

  &__medium-large {
    max-width: 220px;
    min-height: 60px;
    font-size: 16px;
    font-weight: 600;

    button {
      margin: 0 auto;
    }

    &_round {
      border-radius: 30px;
    }

    &_hollow {
      color: $blue-500;
      border: 1px solid $blue-500;
    }

    &_with-blue-gradient {
      color: $main-white;
      background: linear-gradient(45deg, $main-purple 0%, $secondary-blue 100%);
    }
  }

  &__large {
    min-height: 60px;
    flex-basis: 285px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;

    a {
      font-size: 16px;
    }

    &_round {
      border-radius: 30px;
    }

    &_with-red-gradient {
      color: $main-white;
      background: linear-gradient(45deg, $red-100 0%, $red-300 100%);
    }

    &_with-blue-gradient {
      color: $main-white;
      background: linear-gradient(45deg, $main-purple 0%, $secondary-blue 100%);
    }
  }

  &__without-border {
    border: none;
  }

  &_with-blue-shadow {
    box-shadow: 0 10px 20px rgba(41, 177, 204, 0.3);
  }

  &__with-two-buttons {
    @include align-center-center;
  }

  &__first {
    margin-right: 10px;
  }

  &__caption {
    @include align-center;
    margin: 8px 0 24px 0;
    @include font-size(16, 26, 300);
    color: map-get($color-list, "grey-100");

    &.small {
      @include font-size(14);
    }

    &.align-left {
      justify-content: flex-start;
    }

    &.align-right {
      justify-content: flex-end;
    }
  }

  &__border {
    border-width: 2px;
  }

  &_green {
    color: $main-white;
    border: none;
    background-color: $main-green;
    border: 2px solid $main-green;
  }

  &_red {
    color: $main-white;
    border: none;
    background-color: $main-red;
    border: 2px solid $main-red;
  }

  &_blue {
    color: $main-white;
    border: none;
    background-color: $button-step;
  }

  &__purple {
    color: $main-white;
    border: none;
    background-color: $main-active;

    &_shadow {
      box-shadow: 0 10px 20px 0 rgba($main-active, 0.3);
    }
  }

  &_blue-border {
    padding: 10px 0;
    color: $secondary-blue;
    border: 2px solid $button-step;
    background-color: transparent;
  }

  &_align-center {
    @include align-center;
  }

  &__with-icon {
    * {
      display: flex;
    }
  }

  &__with-purple-blue-gradient {
    color: $main-white;
    background: $linear-gradient-purple-blue;

    &_shadow {
      box-shadow: 0 10px 20px 0 rgba($box-shadow-blue, 0.5);
    }
  }

  &__delete {
    border: none;
    background: none;

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed!important;
    }
  }

  &__dropdown {
    margin: 0 4px;
    padding: 0;
    line-height: 1;
    border: none;
    background: none;

    &:hover {
      * {
        color: $main-grey !important;
      }
    }

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  &__submit {
    margin-bottom: 25px;
    border: none;
    border-radius: 22px;

    @include for-size(phone) {
      margin-bottom: 15px;
    }
  }

  &__support {
    padding: 10px 0;
    margin-bottom: 30px;
    @include font-size(14, 16, 600);
    border-radius: 20px;
    border: none;
  }

  &__social {
    display: flex;
    padding: 0;
    background: none;
    text-decoration: none;

    &_icon {
      @include align-center;
      padding: 10px 14px;
      min-width: 55px;
      border-right: 1px solid $main-blue;

      &_blue {
        border-right: 1px solid $main-blue;
      }

      &_red {
        border-right: 1px solid $main-red;
      }
    }

    &_text {
      width: 100%;
      line-height: 40px;
      text-align: center;
    }

    &_blue {
      border: 1px solid $main-blue;
      margin-bottom: 25px;
    }

    &_red {
      border: 1px solid $main-red;
    }
  }

  &__billing {
    width: 220px;
    @include align-center;
    margin: 0 10px;
    padding: 20px 25px;
    font-size: 16px;
    line-height: 19px;
    color: $main-purple;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid rgba(159, 153, 203, 0.5);

    &:hover {
      cursor: pointer;
    }

    &_icon {
      margin-right: 10px;
    }
  }

  &__text {
    margin-left: 5px;
  }

  &__without-link {
    a,
    a:hover {
      text-decoration: none;
    }

    &.grey {
      a,
      a:hover {
        color: $main-grey;
      }
    }
  }

  &__without-styles {
    padding: 0;
    border: none;
    background: none;

    &:focus {
      outline: none;
    }

    &.grey {
      color: $main-grey;
    }
  }

  &__copy {
    pointer-events: auto;

    &-hover {
      justify-content: space-between;
      width: 130px;
      border: 1px solid #bbc2c9;
      padding: 5px 10px;
      border-radius: 20px;
      display: none;
    }

    &:hover {
      .button__hover-inactive {
        display: none;
      }
      .button__hover-active {
        display: flex;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 90px;
        }
      }
    }
  }

  &__checkout,
  &__confirm,
  &__edit-form {
    width: 95px;
    padding: 5px 0;
    color: $purple-100;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid $purple-100;
  }

  &__checkout_fill,
  &__confirm_fill,
  &__edit-form_fill {
    color: $main-white;
    background-color: $purple-100;
    box-shadow: 0 0 7px 0 rgba($secondary-purple, 0.36);
  }

  &__confirm {
    &_fill-red {
      color: $main-white;
      background: $secondary-red;
      border: 1px solid $secondary-red;
    }

    &_red {
      color: $secondary-red;
      border: 1px solid $secondary-red;
    }
  }

  &__group {
    @include align-center-center;

    &_center {
      width: 100%;
      @include align-center;

      & > button {
        margin: 0 8px;
      }
    }

    &_right {
      margin-bottom: 10px;
      @include align-right-center;
      @include for-size(phone) {
        @include align-center;
      }

      & > button {
        margin: 0 8px;
      }
    }

    &_left {
      @include align-left-center;
      @include for-size(phone) {
        @include align-center;
      }

      & > button {
        margin: 0 8px;
      }
    }
  }

  &__plan-upgrade {
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    background-color: $purple-100;
    font-size: 14px;
    color: $main-white;
    margin: 0 auto;
  }

  &__candidate {
    @include align-center;
    width: 150px;
    height: 35px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    background: none;
    @include font-size(14, 16, 500);
    border: 2px solid $grey-700;
    text-align: center;

    &_with-icon {
      display: flex;
      align-items: center;
      margin: 0;
      @include font-size(14, 16);
      width: auto;
      border: none;
      color: $grey-400;
    }

    &-text {
      margin-right: 8px;
      color: map-get($color-list, "grey-100");
      font-weight: 500;
    }

    &.green {
      color: $main-white;
      border-color: $green-400;
      background-color: $green-400;
    }

    &.red {
      color: $main-white;
      border-color: $red-200;
      background-color: $red-200;
    }

    &.grey {
      color: $main-grey;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__send {
    width: 100%;
    max-width: 95px;
    padding: 4px;
    @include font-size(16);
    color: $main-white;
    border: none;
    border-radius: 4px;
    background-color: $button-step;

    &:disabled {
      background-color: $button-disabled;
    }
  }

  &__clear-all {
    @include for-size(tablet) {
      margin-bottom: 24px;
    }
    border: 1px solid $main-link !important;
    min-width: 97px !important;
  }

  &__cancel {
    border: 1px solid rgba(118, 132, 147, 0.502868) !important;
    margin-right: 5px;

    @media (max-width: 991px) {
      position: absolute;
      right: 15px;
      top: 5px;
    }
  }

  &__create-job {
    @include align-center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 30px;
    padding: 10px 0;
    border-radius: 25px;
    text-align: center;
    text-transform: uppercase;
    color: $main-white;
    border: none;
    background: linear-gradient(45deg, $main-purple 0%, $secondary-blue 100%);

    &.disabled {
      background: $button-disabled;
      pointer-events: none;
    }

    .icon {
      margin-right: 10px;
      font-size: 24px;
      line-height: 1;
    }
  }
}

.hide-on-mobile {
  @include for-size(phone) {
    display: none;
  }
}

// BUTTONS NEW UI-KIT
.n-button {
  &__wrapper {
    display: flex;
    margin-bottom: 12px;

    &.center {
      @include align-center;
    }

    @include for-size(phone) {
      justify-content: center;
    }
  }

  &__small,
  &__medium,
  &__large {
    display: flex;
    justify-content: center;
    min-width: 90px;
    border: none;
    line-height: 1;
    &:hover:not(.disabled):not(:disabled) {
      box-shadow: 0px 0px 10px rgba($box-shadow-gray, 0.5);
    }

    &-border {
      min-width: 90px;
      background-color: transparent;
      border: 1px solid map-get($color-list, "black-100");

      &:hover {
        box-shadow: 0px 0px 10px rgba($box-shadow-gray, 0.5);
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        border-color: $button-disabled;
        color: $button-disabled;
      }
    }

    &:disabled {
      background: $button-disabled !important;
    }

    &:focus {
      outline: none;
    }

    &.copy-link {
      min-width: 105px;
    }
  }

  &__small {
    padding: 10px 24px;
    border-radius: 16px;
    font-size: 12px;
  }

  &__small-border {
    padding: 8px 22px;
    border-radius: 16px;
    font-size: 12px;
    &:hover {
      box-shadow: 0px 0px 10px rgba($box-shadow-gray, 0.5);
    }
  }

  &__medium {
    padding: 14px 32px;
    border-radius: 22px;
    font-size: 16px;
  }

  &__medium-border {
    padding: 12px 30px;
    border-radius: 22px;
    font-size: 16px;
    &:hover {
      box-shadow: 0px 0px 10px rgba($box-shadow-gray, 0.5);
    }
  }

  &__large {
    padding: 20px 40px;
    border-radius: 38px;
    font-size: 22px;
  }

  &__large-border {
    padding: 18px 40px;
    border-radius: 38px;
    font-size: 22px;
    &:hover {
      box-shadow: 0px 0px 10px rgba($box-shadow-gray, 0.5);
    }
  }

  &__full-width {
    width: 100%;
    margin: 0 !important;
  }
}

.n-fluid {
  width: 100%;
}

.n-x-lg {
  width: auto;
  min-width: 220px;

  @include for-size(phone) {
    width: 100%;
    min-width: initial;
  }
}

.n-lg {
  width: auto;
  min-width: 180px;

  @include for-size(phone) {
    width: 100%;
    min-width: initial;
  }
}

.n-md {
  width: auto;
  min-width: 120px;

  @include for-size(phone) {
    width: 100%;
    min-width: initial;
  }
}

.n-sm {
  width: auto;
  min-width: 90px;

  @include for-size(phone) {
    width: 100%;
    min-width: initial;
  }
}

.n-icon {
  display: block;

  &__small {
    width: 12px;
    height: 12px;
  }

  &__medium {
    width: 16px;
    height: 16px;
  }

  &__large {
    width: 24px;
    height: 24px;
  }

  &__small,
  &__medium,
  &__large {
    &.left {
      margin-left: 8px;
    }

    &.right {
      margin-right: 8px;
    }
  }
}

.icon__delete {
  padding-top: 2px;
}

.n-stop-mobile-wrapper {
  @include for-size(phone) {
    min-height: 44px;
  }
}

.n-stop-mobile {
  @include for-size(phone) {
    position: fixed;
    bottom: 16px;
    z-index: 1000;
    max-width: 190px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.arrow-buttons {
  padding-top: 50px;
  padding-left: 15px;
}
