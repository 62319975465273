@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.add-new-card {
  padding-top: 26px;
  font-size: 14px;
  line-height: 16px;
  text-align: left;

  &__icon {
    margin-right: 16px;

    * {
      fill: $purple-100;
    }
  }

  &__button {
    padding: 0;
    border: none;
    background: none;
    color: $grey-300;
  }
}