@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.edit-from {
  width: 100%;

  .button__group {
    margin-left: 30px;
  }

  @include for-size(tablet) {
    .button__group {
      margin-left: 0px;
    }
  }

  @include for-size(phone) {
    .button__group {
      margin-left: 30px;
    }
  }
}

.edit-profile__field {
  @include align-top;
  position: relative;

  svg {
    width: 15px;
    margin-top: 5px;
    margin-right: 18px;
  }

  &.last {
    margin-bottom: 15px;
  }
};

.edit-profile__input {
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  background: transparent;
  border-bottom: 1px solid $white-200;

  &.disabled {
    border: none;
  }

  &.error {
    border-bottom: 1px solid $main-red;
  }
};

.edit-profile__input-error {
  font-size: 12px;
  line-height: 14px;
  color: $main-red;
  position: absolute;
  bottom: 0;
  left: 30px;
};
