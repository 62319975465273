@import '~assets/styles/abstracts/colors';

// GRADIENT NEW UI-KIT
$gradient-list: (
  blue: linear-gradient(225deg, #2BABE3 0%, #6657B2 100%),
  red: linear-gradient(45deg, #E95C5C 0%, #E83B3B 100%),
);


@each $name, $value in $gradient-list {
  .n-gradient-#{$name} {
    background: $value;
    border: none;
  }
}

@each $name, $value in $color-list {
  .n-#{$name} {
    color: $value;
  }

  .n-bg-#{$name} {
    background-color: $value;
  }

  .n-border-#{$name} {
    border-color: $value;
  }

  .n-svg-#{$name} {
    fill: $value;

    * {
    fill: $value;
    }
  }
}
  
