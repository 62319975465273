@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.comapany__chart {
  margin-top: 20px;
  padding: 20px;
  background: $main-white;
}

.comapany__chart-header {
  margin-bottom: 40px;
  @include align-center-center;
}

.comapany__chart-select {
  width: 270px;
  font-size: 14px;
  line-height: 18px;

  .select__control {
    border-radius: 20px;
    min-height: 40px;
    box-shadow: none;

    &--is-focused {
      border-color: $main-active !important;
    }
  }

  .select__value-container {
    padding-left: 16px;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__dropdown-indicator {
    padding-right: 16px;
  }

  .select__menu {
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 10px;
    overflow: hidden;
  }

  .select__option {
    padding:8px 16px;
    border-radius: 10px;

    &:hover {
      background-color: map-get($color-list, "black-5");
    }
  }

  &.initial {
    .select__single-value {
      color: rgba(101,111,141,0.5);
    }
  }

  &.invalid {
    .select__control {
      border-color: $main-red;
    } 
  }
}

.comapany__chart-labels-wrapper {
  @include align-left-center;
}

.comapany__chart-label {
  margin-right: 20px;
}

.comapany__chart-label-text {
  margin-right: 5px;
}

.comapany__chart-body {
  width: 100%;
  height: 300px;
}