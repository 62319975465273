.modal-dialog {
  margin: .5rem auto;
  
  &.confirm {
    max-width: 300px;
    margin-top: 200px;


    .modal-content {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      border-radius: 20px;
    }
    
    .modal-header,
    .modal-footer {
      border: 0; 
      .button__group {
        width: 100%;
      }
    }

    .modal-header {
      padding-top: 10px;
      padding-bottom: 10px;

      .close {
        &:focus {
          outline: none;
        }
      }
    }

    .modal-body {
      padding: 0;
    }

    .modal-body,
    .modal-footer {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}