@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.profile-card {
  &__header {
    @include align-center;
    flex-wrap: wrap;
    padding: 50px 50px 25px 50px;
    position: relative;
    
    span {
      display: block;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      text-transform: initial;
    }
  
    .button__edit-profile {
      padding: 0;
      border: none;
      background: none;
      position: absolute;
      top: 15px;
      right: 15px;
  
      &:focus {
        outline: none;
      }
    } 
  }


}
