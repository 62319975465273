@import '~assets/styles/abstracts/colors';

.question {
  &__published {
    padding: 10px 0 25px 0;
    font-size: 12px;
    line-height: 14px;
    color: $text-black;
  }

  &__group-btns {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }

  &__add-btn {
    width: 100%;
    height: 100px;
    margin-bottom: 35px;
    padding: 15px;
    text-align: center;
    background: map-get($color-list, "black-5");
    border-radius: 20px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);

    .dropdown {
      display: inline-block;
    }

    .dropdown-menu {
      max-width: 200px;
      @include for-size(phone) {
        max-width: 220px;
        a {
          font-size: 15px;
          line-height: 24px;
        }
      }
      a {
        padding: 5px;
        color: $main-link;
        text-align: center;
        i {
          color: map-get($color-list, "black-30");

          svg {
            margin: 0;
          }
        }
        &:hover {
          background: $header-meud-dropdwon-hover;
        }
      }
    }

    > span {
      display: block;
      color: map-get($color-list, "purple-100");
      svg {
        margin: 15px;
      }
    }

    &:focus {
      outline: none;
    }
    
    &:hover {
      background-color: map-get($color-list, "purple-10")
    }
  }
}

.textarea.questions, .form-description {
  .rdw-option-wrapper {
    background-color: map-get($color-list, "grey-30");
    border: 1px solid map-get($color-list, "grey-30");
  }
}

.react-autosuggest__suggestions-container--open {
  background-color: $main-white;
  border-radius: 20px;
  border: 1px solid map-get($color-list, "grey-50");
  position: absolute;
  width: 350px;
  z-index: 3;
  max-height: 210px;
  overflow: auto;

  @include for-size(phone) {
    width: 320px;
  }

  .react-autosuggest__suggestion {
    width: 100%;
    padding: 5px 20px !important;
    border-bottom: 1px solid map-get($color-list, "grey-30");

    &:hover {
      background-color: $grey-100;
    }

    &:last-child {
      border: none;
    }
  }
}
