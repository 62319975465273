@import '~assets/styles/abstracts/colors';

.input-icon {
  position: absolute;
  left: 16px;
  top: 20px;
  transform: translateY(-45%);

  &.phone {
    height: 22px;
    width: 15px;
    left: 20px;

    g {
      fill: #D6DADE;
    }
  }

  &.flag {
    height: 22px;
    width: 42px;
    left: 0px;
    top: 24px;

    &.international {
      top: 22px;
    }
  }

  &.right {
    left: auto;
    right: 15px;
  }
};

.input-icon-wrapper {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;

  &.right {
    left: auto;
    right: 0;
  }
}

.input-icon_right {
  margin-right: 10px;
}

.not-clickable-icon {
  pointer-events: none;
};

.step-left {
  margin-left: 6px;
};

.icon-with-text {
  @include align-left-center;
  height: 24px;
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px solid $light-grey;
  color: $main-grey;
  @include font-size(16, 23, 400);
  overflow: hidden;
  white-space: nowrap;
  transition-property: width, height;
  transition-duration: 1.2s;

  &.sm {
    width: 175px;
  }

  &.md {
    width: 220px;
  }

  &.lg {
    width: 260px;
  }

  .icon-label,
  .icon-text {
    @include font-size(12, 22, 400);
  }

  .icon-label {
    display: block;
    padding: 0 2px;
  }

  .icon-text {
    display: none;
    padding: 0 2px;
  }

  &.icon-button {
    padding: 0;
    background: none;

    .icon-wrapper {
      .far {
        padding: 3px;
      }
    }

    .icon-with-text,
    .icon-label { 
      line-height: 23px;
    }

    &:focus {
      outline: none;
    }
  }

  &.show {
    width: 100%;
    max-width: 520px;

    .icon-label {
      display: none;
    }

    .icon-text {
      display: block;
    }
  }

  &.hide {
    height: 24px;

    &.sm {
      width: 175px;
    }
  
    &.md {
      width: 260px;
    }

    .icon-label {
      display: block;
    }

    .icon-text {
      display: none;
    }
  }

  @include for-size(tablet) {
    &.show {
      width: 100%;
      height: 60px;
      white-space: initial;
    }
  }

  .fas,
  .far {
    padding: 4px 3px;
    font-size: 22px;
    line-height: 1;
  }
}

.fa,
.fas,
.far {
  @for $i from 4 through 20 {
    &.m#{$i} {
      margin: #{$i}px;
    }
  
    &.mt#{$i} {
      margin-top: #{$i}px;
    }
  
    &.mb#{$i} {
      margin-bottom: #{$i}px;
    }
  
    &.mr#{$i} {
      margin-right: #{$i}px;
    }
  
    &.ml#{$i} {
      margin-left: #{$i}px;
    }
  }

  &.xs {
    font-size: 12px;
  }

  &.xsm {
    font-size: 14px;
  }

  &.sm {
    font-size: 16px;
  }

  &.md {
    font-size: 22px;
  }

  &.lg {
    font-size: 34px;
  }

  &.xlg {
    font-size: 52px;
  }

  &.blue {
    color: $secondary-blue;
  }

  &.purple {
    color: $purple-100;
  }

  &.grey {
    color: $grey;
  }

  &.grey-light {
    color: $light-grey;
  }

  &.gold {
    color: $gold
  }
  
  &.red {
    color: $main-red;
  }

  &.hover-red {
    &:hover {
      color: $main-red !important;
    }
  }
}
