@import "~assets/styles/abstracts/colors";
@import "~assets/styles/abstracts/mixins";

.profile {
  padding-top: 55px;
  padding-bottom: 55px;
  text-align: center;

  .progress__total-title {
    margin-bottom: 10px;
  }

  .progress__left-label {
    height: 40px;
    margin-bottom: 15px;
  }

  .profile__text {
    text-align: initial;
    span {
      margin-right: 5px;
      margin-left: 0;
    }
  }

  &__change-plan {
    border-color: $main-purple;
    padding: 10px 12px;
    text-align: center;

    &:hover {
      color: $main-purple;
      text-decoration: none;
    }
  }

  &__cancel-sunscription {
    color: $grey-800;

    span {
      margin-left: 10px;
      font-weight: 600;
    }
  }

  &__link {
    margin-bottom: 45px;
    text-align: left;

    a {
      display: inline-block;
      padding: 10px 13px;
      font-size: 16px;
      line-height: 19px;
      color: $purple-600;
      border-radius: 6px;
      border: 1px solid rgba(159, 153, 203, 0.5);
      background-color: rgba(88, 93, 169, 0.1);

      svg {
        margin-left: 10px;
      }
    }
  }
}

.profile__header {
  text-align: left;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid $grey-200;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: $grey-1200;
  }

  span {
    font-size: 14px;
  }

  svg {
    margin-right: 10px;
  }

  svg.left {
    margin-left: 10px;
  }

  svg.right {
    margin-right: 10px;
  }
}

.profile__number {
  font-size: 20px;
  color: $main-active;
}

.card__link {
  text-align: left;

  > a {
    color: rgba($grey-1200, 0.5);
  }
}

.card__link-update {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 0;
  > a {
    color: rgba($grey-1200, 0.5);
  }
}

.card__block {
  position: relative;
  .billing-checkout {
    margin: 0;
    padding-top: 26px;
    padding-left: 0;
    padding-right: 0;
  }
}

.card__block-title {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
}
