@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.share {
    max-width: 700px!important;
    
    .modal-body {
        padding: 30px 60px 10px;

        @include for-size(tablet) {
            padding: 1rem;
        }
    }

    &__row {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
    }

    &__info {
        padding: 15px;
    }
}

.share__block {
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    padding: 0 15px;
}

.share__close {
    padding: 0;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    background: none;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
}

.share__title {
    margin-bottom: 8px;
}

.share__text {
    margin-bottom: 8px;
}

.share__privacy-link {
    @include font-size(14);
    text-decoration: underline;
    color: $main-blue;

    &:hover {
        text-decoration: none;
    }
}

.share-input__wrapper {
    display: flex;
    position: relative;
}

.share-input__field {
    width: 100%;
    margin-right: 16px;
    padding: 8px 16px;
    box-shadow: none;
    border: 1px solid map-get($color-list, "grey-50");
    border-radius: 22px;

    &:focus {
        border-color: map-get($color-list, "purple-100");
        outline: none;
    }
}

.share__bottom {
    text-align: center;
}

.share__question {
    margin-bottom: 30px;

    &-item {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
    }
    &-icon {
        margin-right: 15px;
    }
    &-text {
        margin-left: 30px;
        margin-bottom: 20px;
        line-height: 21px;
    }
    &-bulk {
        margin-top: 50px;
    }
}
