@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.status-job {
  margin: 0 20px 0 0;
  padding: 3px 10px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border-radius: 12.5px;
  border: 1px solid $main-grey;
  background-color: rgba($main-grey, 0.5);
  color: $main-grey;

  &.live {
    border: none;
    background-color: rgba(129, 215, 127, 0.5);
    color: $seconday-grey;
    width: 47px;
    height: 22px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.disabled {
    border-color: $secondary-red;
    background-color: rgba($secondary-red, 0.5);
    color: $secondary-red;
  }

  &.closed {
    color: $seconday-grey;
    border-color: map-get($color-list, "yellow-30" );
    background-color: map-get($color-list, "yellow-30");
  }

  &.preview {
    width: 100px;
    margin: 0;
    padding: 7px 0;
    border-radius: 17px;
    @include font-size(16, 19, 600);
    color: $seconday-grey;

    &.live {
      border: none;
      height: auto;
      border-color: $gren-500;
      background-color: rgba($gren-500, 0.5);
      color: $seconday-grey;
    }
    &.disabled {
      border: none;
    }
  }

  &.expired_closed {
    color: $seconday-grey;
    border-color: rgba(232, 59, 59, 0.2);
    background-color: rgba(232, 59, 59, 0.2);
  }
}