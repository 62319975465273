.link__create-company,
.link__create-interview {
  @include align-center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
  padding: 19px 0;
  border-radius: 15px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid map-get($color-list, "purple-100");

  &:hover {
    text-decoration: none;
    background-color: map-get($color-list, "purple-10")
  }
}

.link__create-company-icon,
.link__create-interview-icon,
.link__create-company-text,
.link__create-interview-text {
  display: block;
  width: 100%;
  color: $main-active;
}

.link__create-company-icon,
.link__create-interview-icon {
  margin-bottom: 10px;
  font-size: 40px;
}

.link__right {
  display: block;
  width: 100%;
  text-align: right
}

.link__edit {
  margin-top: 2px;
  margin-left: 4px;
}