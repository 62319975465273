@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.add-user-banner {
  position: relative;

  &__icon {
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
  }

  &__text {
    margin-left: 40px;
    border: 1px solid $grey-700;
    background-color: $white-100;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 20px;
    color: $grey-800;
    position: relative;

    span {
      margin: 0 5px;
      font-weight: 700;
    }

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      margin-top: -5px;
      background-color: $white-100;
      border-bottom: 1px solid $grey-700;
      border-right: 1px solid $grey-700;
      transform: rotate(150grad);
      position: absolute;
      top: 50%;
      left: -6px;
    }
  }
}