@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.add-more {
  display: inline-block;
  padding: 0 20px;

  &__btn {
    @include align-left-center;
    padding: 10px 0;
    font-size: 12px;
    line-height: 14px;
    color: $main-purple;
    border: none;
    background-color: transparent; 

    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    margin-right: 10px;
  }
}