// Main colors
$main-color: #001E45;
$main-black: #000000;
$main-white: #ffffff;
$main-link: #595959;
$main-active: #5a2af1;
$main-purple: #5a2af1;
$main-red: #E83B3B;
$main-blue: #187FB8;
$main-grey: #768493;
$main-green: #59CC4C;
$main_loader: rgba($main-white, .3);

$light-blue: #ACE0F5;
$light-grey: #979797;

$seconday-grey: #595959;
$secondary-purple: #585ba8;
$secondary-blue: #49C7ED;
$secondary-red: #FC7979;
$secondary-brand: #F4F1ED;
$secondary-violet: #D2CDE840;

$tertiary-green: #0FAC00;
$tertiary-red: #E11F1F;

$white-100: #F7F9FD;
$white-200: #CBD3D9;

$grey: #cccccc;
$grey-50: #C2C2C2;
$grey-100:  #f8f8f8;
$grey-200:  #d8d8d8;
$grey-300:  #1e384b;
$grey-400:  #8798AD;
$grey-500:  #BAC1C9;
$grey-600:  #CAD0D6;
$grey-700:  #B7BDCB;
$grey-800:  #727A82;
$grey-900:  #9DA4AC;
$grey-1000: #B6BDC5;
$grey-1100: #656F8D;
$grey-1200: #1E384B;

$gold:#FFD231;

$red-100: #E95C5C;
$red-200: #FF7777;
$red-300: #E83B3B;

$blue-100:#2AABE4;
$blue-300: #DDEAF1;
$blue-400: #48C7ED;
$blue-500: #4CB3E0;
$blue-600: #5094CC;
$blue-700: #34495E;
$blue-800: #125DC9;


$green-400: #59CC4C;
$gren-500: #4DAE4B;

$purple-100:#5A2AF1;
$purple-200: #E3E4F1;
$purple-300: #9f99cb;
$purple-400: #585DA9;
$purple-500: #585da9;
$purple-600: #5957A6;
$purple-700: #685bc7;

$violet-100:#6657B2;

$spam-yelow: #FFF1C4;
$spam-red: #F8CECF;

// Header
$header-main: #f4f1ed;
$header-search-bg: rgba($main-white, .1);
$header-search-border: rgba($main-white, .3);
$header-meud-dropdwon-hover: rgba($grey-700, .1);

//Footer
$footer-main: #f4f1ed;

// Buttons
$button-step: $secondary-blue;
$button-disabled: #D6DBDF;

// Add user 
$add-user-border: rgba($purple-300, .5);
$add-user-bg: rgba($purple-300, .1);
$add-new-user-border: rgba($main-grey, .5);

// Forms inputs
$text-editor-toolbar: rgba($grey-200, 0.28);
$label-secondary: rgba($grey-300, 0.5);
$overview-bg: $grey-100;
$text-video: #CBCBCB;

// Cards
$card-shadow: rgba($secondary-purple, .36);
$card-gradient: linear-gradient(45deg, $secondary-purple 0%, $secondary-blue 100%);
$card-placeholder: #aab7c4;

// Job Overview
$candidate-date-gradient:  linear-gradient(45deg, $secondary-purple 0%, $secondary-blue 100%);
$beta-gradient: linear-gradient(225deg, #2BABE3 0%, #6657B2 100%);

// Candidates
$cadnidates-violet: #5956A5;
$cadnidates-blue: #617CE1;
$cadnidates-sky: #48C7ED;
$cadnidates-parakeet: #59CC4C;
$cadnidates-orange: #F7C137;
$cadnidates-red: #E83B3B;
$cadnidates-yellow:#FFD231;
$cadnidates-grey:#626e82;

// Candidates
$comments-grey: $cadnidates-grey;

// Mainly for buttons to chage their box shadows
$box-shadow-blue: #29b1cc;
$box-shadow-gray: #626e82;

// Colors for text and links
$text-green: #155724;
$text-black: $grey-300;
$text-white: $main-white;
$link-color: #3C3C3C;


// Gradients and all what connects with it
$linear-gradient-purple-blue: linear-gradient(45deg, $main-purple 0%, $secondary-blue 100%);

// COLORS NEW UI-KIT
$color-list: (
  white: #ffffff,

  black-5: #F4F4F5,
  black-30:#BDC0C8,
  black-50:#9095A3,
  black-80:#4D556B,
  black-100:#212B46,
  black-dark: #101933,

  gray-20:#EAEAEA,
  grey-30:#D6DBDF,
  grey-50:#BBC2C9,
  grey-60:#1e384b,
  grey-70: rgba(77, 85, 107, 0.7),
  grey-100:#768493,

  grey: #595959,
  
  green-100: #59CC4C,
  green-30: #C1F0C0,
  
  red-100: #E83B3B,
  red-200: #FF7777,
  red-300: #E11F1F,
  
  yellow-30: #FFF2C2,
  yellow-50: #FFE998,
  yellow-100: #FFD231,
  
  blue-5:#F4FBFD,
  blue-30:#C0E6F7,
  blue-50:#95D5F2,
  blue-100:#2AABE4,
  blue-700: #34495E,

  purple-10:rgba(90, 42, 241, 0.1),
  purple-50:rgba(90, 42, 241, 0.5),
  purple-100:#5A2AF1,
  
  violet-30:#D2CDE8,
  violet-50:#B3ABD9,
  violet-100:#6657B2,
);
