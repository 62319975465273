@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.billing-checkout {
  position: relative;
  margin: 25px 0;

  .far.lg {
    font-size: 40px;
  }

  .ElementsApp, .ElementsApp .InputElement {
    font-size: 12px;
  }

  .StripeElement {
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(118,132,147,0.5);
  }

  .button__confirm {
    width: 100%;
    padding: 5px 0;
    color: $main-white;
    font-size: 16px;
    line-height: 19px;
    border: none;
    border-radius: 9px;
    background: $main-purple;
  }

  .button__group {
    justify-content: left;
  }

  .button__checkout {
    margin-right: 15px;
    color: $purple-100;
    border-color: $purple-100;
  }

  .button__checkout_fill {
    color: $main-white;
    background-color: $purple-100;
    border-color: $purple-100;
  }

  &__icon {
    * { fill: $purple-100 };
  }

  &__header {
    margin-bottom: 20px;
  }

  &__close {
    padding: 0;
    background: none;
    border: none;
    position: absolute;
    top: 15px;
    right: 20px;
  }

  &__icon {
    margin-bottom: 20px;
  }

  &__icon-success {
    margin-bottom: 20px;
  }

  &__title {
    margin: 0 0 20px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

    svg {
      margin-right: 16px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__row {
    display: flex;
    font-size: 14px;
    line-height: 16px;

    .StripeElement { 
      width: 100%;

      &:first-child {
        margin-right: 15px;
      }
    }

    &_one,
    &_last {
      margin-left: 0;
    }

    &_one {
      .StripeElement { 
        width: 100%;
              
        &:first-child {
          margin-right: 0;
        }
      }
    }

    &_last {
      margin-bottom: 0px;

      .StripeElement {
        margin-bottom: 0px;
      }

      .card-element {
        flex: 2;
        &:first-child {
          margin-right: 15px;
          flex: 4;
        }
        &:last-child {
          flex: 1;
        }
      }
    }
  }
};

.checkout-field {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 10px;
  &__card {
    margin-bottom: 0;
  }
}

.checkout-label {
  font-size: 10px;
  line-height: 12px;
  color: map-get($color-list, "black-80");
  text-align: initial;
  margin-bottom: 0;
  padding: 1px 0px;
}

.checkout-input, .checkout-value {
  font-size: 14px;
  line-height: 16px;
  color: $grey-300;
  text-align: left;
  border: none;
  min-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: $card-placeholder;
  }
  &:focus {
    outline: none;
  }
}

.checkout-value {
  color: map-get($color-list, "black-80");
}

.checkout-country {
  font-size: 14px;
  line-height: 16px;
  color: $grey-300;
  text-align: left;
  height: 20px;

  .select__control {
    border: none;
    border-bottom: 1px solid #CBD3D9;
    border-radius: 0;
    height: 20px;
    min-height: 20px;

    &.select__control--is-focused {
      border: none;
      border-bottom: 1px solid #CBD3D9;
      box-shadow: none;
    }
  }
  .select__indicator-separator {
    display: none;
  }
  .select__value-container {
    padding: 0;
    @include font-size(14, 16);
    color: map-get($color-list, "black-80");
    position: initial;
  }
  .select__single-value {
    height: 20px;
  }
  .select__dropdown-indicator {
    padding: 0;
  }
  svg {
    fill: map-get($color-list, "black-100");
  }
}

.billing-checkout .StripeElement {
  border-bottom: none;
}

.billing-header {
  @include font-size(18, 27, 500);
  text-align: center;
  color: map-get($color-list, "black-80");
  margin: 15px;
  margin-bottom: 0;

  @include for-size(tablet) {
    margin: 15px 25px 0;
  }
}
