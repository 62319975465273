@import "~assets/styles/abstracts/mixins";
@import "~assets/styles/abstracts/colors";

.dzu-dropzone {
  overflow: hidden;
  border: none;
  border-radius: 20px;
  min-height: 255px;
  margin-bottom: 25px;
  display: grid;
  width: 100%;
  display: block;

  .dzu-removeButtonContainer {
    width: 100%;
    min-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__unsafe {
    background-color: #f4f4f5;
    width: 100%;
    height: 100%;
    display: grid;

    &-text {
      color: #1e384b;
      font-size: 14px;
      text-align: center;
      max-width: 440px;
    }

    &-content {
      flex-direction: row;
      display: flex;
      color: #1e384b;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-top: 40px;
    }

    &-email {
      color: rgb(41, 171, 228);
      font-size: 14px;
    }
  }
  .dzu-removeButton {
    cursor: pointer;
    &.uploading,
    &.remove {
      display: block;
    }
    &.uploading {
      cursor: auto;
    }
  }
  &.small {
    min-height: 200px;
  }
  &.big {
    min-height: 340px;
  }
  &.upload {
    min-height: 211px;
    padding: 0;
    &.big {
      min-height: 340px;
    }
    .dzu-previewContainer {
      padding: 20px 3% 10px;
      border-radius: 20px;
    }
    .dzu-percentContainer {
      padding: 15px 0;
    }
    .dzu-removeButton {
      &.uploading,
      &.remove {
        display: inline;
        padding: 0;
      }
    }
    .dzu-removeButtonContainer {
      display: block;
    }
    .big {
      .dzu-previewContainer {
        padding: 50px 3% 30px;
      }
    }
  }

  @include for-size(tablet) {
    min-height: 200px !important;
  }
}
.dzu-previewContainer {
  border: none;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23595959FF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 100%;
  &.success {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%2359CC4CFF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  &.error {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23E83B3BFF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
}

.dzu-previewFileNameContainer {
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;

}
.dzu-dropzone__unsafe-text-container {
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 340px;
}

.dzu-iconFile {
  height: 35px;
}

.dzu-inputFileName {
  width: 100%;
  font-size: 22px;
  line-height: 32px;
  color: $grey-300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  background: transparent;
  border-bottom: 1px solid $white-200;

  &:focus {
    outline: none;
  }
}

.dzu-previewFileName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  color: $main-link;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 80%;
  margin-right: 5px;

  &.extension {
    overflow: visible;
  }
}

.dzu-previewFileNameIcon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  label {
    margin-bottom: 0.2rem;
  }
}

.dzu-span {
  padding: 0 5px;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: $main-link;
  font-weight: 400;
}

.dzu-removeButton,
a.dzu-removeButton {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: rgba(255, 0, 0, 0.5);
  text-decoration: underline !important;
  margin-bottom: 0;

  &.uploading {
    text-decoration: none;
    color: $main-link;
  }

  &.view {
    color: $main-link;
    &:disabled {
      color: $grey-900;
    }
  }
}

.dzu-percentContainer {
  line-height: 1;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;

  &.small {
    padding: 3px 0;
  }

  @include for-size(tablet) {
    padding: 3px 0 !important;
  }
}

.dzu-percent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: map-get($color-list, "black-50");
}

.dzu-progress {
  width: 100%;
  height: 3px;
  border-radius: 12px;
  background-color: map-get($color-list, "black-50");

  // IE
  color: $purple-100;

  // Clear values for Chrome and Firefox
  &[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 100%;
    height: 3px;
  }

  &[value]::-webkit-progress-value {
    background-color: $purple-100;
    border-radius: 12px;
  }

  &::-webkit-progress-bar {
    border-radius: 12px;
    background-color: map-get($color-list, "black-50");
  }

  &[value]::-moz-progress-bar {
    background-color: $purple-100;

    border-radius: 3px;
    background-size: 100% 100%;
  }
}

.dzu-inputContainer {
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  max-width: 540px;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23595959FF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.dzu-inputLabel {
  position: inherit;
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dzu-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  text-align: center;

  color: $main-link;

  &.padding {
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

.dzu-message {
  font-size: 12px;
  line-height: 15px;
  color: $main-grey;
  margin-top: 0.5rem;
}
