@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.profile-card {

  &__list {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
  }

  &__item {
    @include align-left-center;
    padding: 7px 0 !important;

    & > div {
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
    }
  }

  &__item-icon {
    display: flex !important;
    @include align-left-center;
    width: 15px;
    margin-right: 5px;

    svg {
      max-width: 12px;
    }
  }

  &__item-text {
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $text-black;
  }
};
