@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

#invite-all-input {
  display: none;
}

.input-upload-wrapper {
  @include align-center-center;
  margin-bottom: 8px;
  width: 100%;
  padding: 4px 4px 4px 25px;
  font-size: 14px;
  line-height: 16px;
  background: $main-white;
  border: 1px solid map-get($color-list, "grey-50");
  border-radius: 20px;
  box-sizing: border-box;

  label {
    margin: 0;
  }

  &.invalid {
    border-color: $main-red; 
  }
}

.invite-all__title,
.invite-all__text {
  @include font-size(18);
  text-align: center;
}

.invite-all__title {
  margin-bottom: 20px;
  font-weight: 600;
}

.invite-all__text {
  margin-bottom: 40px;
}

.input-upload__file-name {
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.invite-all-input__custom-file-upload {
  margin-left: 5px;
  margin-bottom: 0;
  padding: 7px 25px;
  color: $main-white;
  border-radius: 20px;
  background-color: #5956A5;
  box-shadow: 0 0 3px 0 rgba(0,0,0,0.05);
}

.invite-all-input__label {
  margin-bottom: 10px;
}

.invite-all-input__caption {
  color: $main-grey;
  @include font-size(14);
  text-align: center;
}

.invite-all-input__caption-info {
  margin-left: 5px;
  font-weight: 600;

  > span {
    margin-left: 5px;
  }
}

.invite-all-input__caption-info-icon {
  margin-bottom: 5px;

  g {
    fill:$main-grey;
  }
}

.invite-all-input__caption-link {
  padding-left: 5px;
  font-weight: 600;
  text-decoration: underline;
  color: $main-grey;

  &:hover {
    text-decoration: none;
  }
}


.form-invite-all {
  .button__group {
    @include align-center;
    margin-top: 70px;
    margin-bottom: 40px;

    .button {
      margin: 0 10px;
    }
  }
}