@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.profile-card {
  margin-bottom: 20px;
  text-align: center;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 0 7px rgba(88,91,168,.36);

  .billing {
    .show-form {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}