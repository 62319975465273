@import '~assets/styles/abstracts/colors';

.tooltip {
  z-index: 1150 !important;
  &__icon {
    display: inline-flex;
    width: 12px;
    height: 12px;
    margin-top: 0;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;

    svg g {
      fill: map-get($color-list, "black-100") !important;
    }

    &.blue {
      svg g {
        fill: map-get($color-list, "blue-100") !important;
      }
    }

    &.purple {
      svg g {
        fill: map-get($color-list, "purple-100") !important;
      }
    }

    &.file-tooltip {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  &__icon-people {
    width: 40px;
    height: 40px;
  }

  &__icon-duplicate {
    width: 20px;
    height: 20px;

    .duplicate {
      padding-top: 3px;
      padding-left: 2px;
    }
  }

  &__group {
    display: flex;
    justify-content: flex-end;
  }

  &__edit {
    display: flex;
  }

  &__icon-with-message {
    margin-left: 0;
    margin-right: 0;
    width: 15px;
    justify-content: flex-end;
    pointer-events: none;

    &:hover {
      width: 135px;
      justify-content: normal;
      margin-top: -1px;
    }

    &.tooltip__success {
      width: 30px;
      justify-content: flex-end;
      margin-top: 2px;
    }
  }

  &__icon-share {
    width: 20px;
    height: 20px;
    margin-left: 0;
  }
}

.tooltip-right__custom, .tooltip-bottom__custom {
  left: 5px !important;
    
  .arrow {
    width: .5rem !important;
    height: .5rem !important;
    border: 1px solid $grey-700 !important;
    border-width: 1px 1px 0 0 !important;
    border-right-color: none !important;
    background: #fff !important;

    &:before {
      display: none;
    }
  }

  .tooltip-inner {
    padding: 0.5rem !important;
    color: $grey-800;
    background-color: #fff;
    border-radius: .25rem;
    border: 1px solid $grey-700;
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins";
    z-index: 2;
  }
}
.tooltip-inner {
  white-space: break-spaces;

  .tooltip__link {
    color: $main-white;
    text-decoration: underline;
  }
}

.tooltip-bottom__custom {
  top: 5px !important;
  z-index: 3 !important;
  .arrow {
    transform: rotate(-45grad) !important;
    top: 2px !important;
  }

  .tooltip-inner {
    padding: 5px 10px !important;
    border-radius: 20px;
    font-size: 14px;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.tooltip-right__custom .arrow {
  transform: rotate(250grad) !important;
  left: 2px !important;
}
