.card-element {
  width: 100%;
}

.card-element-text {
  min-height: 25px;
  text-align: left;
  margin-bottom: 5px;
  @include font-size(12);

  &.error {
    color: red;
  }

  &.postal-code {
    height: auto;
    margin-bottom: 0;
  }
}

#postalCode {
  margin-bottom: 0;
}
