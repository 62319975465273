@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.company__overview-jobs-title {
  padding: 25px;
  text-align: center;
  @include font-size(16);
  color: $grey-800;
}

.company__overview-jobs-title-bold,
.company__overview-jobs-title-link {
  @include font-size(16, 19, 700);
  color: $grey-800;
}

.company__overview-jobs-title-bold,
.company__overview-jobs-title-link {
  margin-left: 5px;
}

.company__overview-jobs-title-link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: $main-black;
  }
}