@import '~assets/styles/abstracts/colors';
@import '~assets//styles/abstracts/mixins';

#comapny-logo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.logo-wrapper {
  @include align-center;
  width: 100%;
  height: 136px;
  outline: none;
  border-radius: 20px;
  border: 1px solid map-get($color-list, "purple-100");
  box-shadow: 0 0 3px 0 rgba(0,0,0,0.05);
  position: relative;
}

.logo-button-text {
  @include align-center;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 14px;
  color: map-get($color-list, "purple-100");

  span {
    display: block;
    width: 100%;
    margin: 5px 0;
    text-align: center;
  }
}

.logo-button-delete {
  position: absolute;
  top: 10px;
  right: 10px;
}

.logo-img-wrapper {
  position: absolute;
}