@import '~assets/styles/abstracts/colors';

.lds-ring {
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 60px;
  position: relative;
}

@keyframes lds-ring-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader__component {
    display: none;
  }
}

.loader-page {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  background-color: $main_loader;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.transferred-loading .loader-page {
  z-index: 1100;
}
