@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.review {
  &__finish-dates {
    display: flex;
    gap: 10px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    > div {
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__redirect-url {
    font-size: 14px;
    line-height: 16.8px;
    .label__main {
      color: map-get($color-list, "black-100");
    }
    .label__secondary {
      color: $main-grey;
    }
    input {
      padding: 10px 16px;
    }
  }

  .input-wrapper {
    @include for-size(phone) {
      max-width: 320px;
    }
  }
}

.review {
  &__email {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 260px;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__avaliability-switcher {
    @include align-center-center;
    margin-bottom: 20px;
  }

  &__avaliability-switcher-text {
    width: 85%;
    @include align-left-center;
    
    & .label {
      margin-bottom: 0 !important;
    }
  } 

  &__table-header {
    @include align-center-center;
    padding: 12px 16px;
  }

  &__table-header-icons {
    @include align-center;

    & > i {
      @include align-center;
      width: 45px;
    }
  }

  &__table-title {
    @include align-left-center;
    margin-bottom: 8px;
    color: map-get($color-list, "violet-100");
  }

  &__table-main {
    max-height: 220px;
    overflow: hidden;
    overscroll-behavior-y: contain;
    margin-bottom: 50px;

    &:hover {
      overflow: auto;
    }
  }

  &__table-row {
    @include align-center-center;
    padding: 8px 16px;
    border-bottom: 1px solid map-get($color-list, "grey-50");

    &.disabled {
      color: map-get($color-list, "grey-50");

      .review__email {
        color: map-get($color-list, "grey-50");
      }
    }
  }

  &__checkbox-container {
    @include align-center;
    width: 92px;
  }

  &__checkbox-wrapper {
    @include align-center;
    width: 46px;
  }

  &__info-text {
    margin-bottom: 16px;
    padding: 12px 16px;
    text-align: center;
  }
}

.job__info-caption-container{
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 50px;
  @include for-size(phone) {
    margin-top: 40px;
    gap: 11px;
  }
}

.job__info-caption {
  width: 100%;
  padding-left: 25px;
  position: relative;
  a {
    text-decoration: underline;
  }


  &.terms-conditions {
    font-size: 14px;
    line-height: 21px;
    color: #595959;
    margin-bottom: 0;

   
  }
}

.job__info-text{
  @include font-size(14);
  color: #595959;
}

.job__info-icon {
  position: absolute;
  top: 3px;
  left: 0;
}

.job__video-container {
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;

  &.visible {
    opacity: 1;
    height: auto;
    transition: opacity 1s ease-in;
  }
}

.job__video {
  border-radius: 20px;
  background-color: $main-white;
  margin: 20px 0 0;
  padding: 25px 10px;
  border: 1px solid map-get($color-list, "grey-30");

  &-title {
    text-align: center;
    padding-bottom: 15px;
    @include for-size(phone) {
      padding-top: 15px;
    }
  }

  &-description {
    text-align: center;
    white-space: pre-line;
    padding-bottom: 20px;
  }

  &-buttons {
    padding: 0px 15px;
  }

  &-or {
    text-align: center;
    padding: 10px;
  }

  &-icon {
    margin-left: 5px;
  }

  &-blocked {
    position: absolute;
    top: -1px;
    left: 14px;
    right: 14px;
    z-index: 1;
    text-align: center;
    padding: 10px 30px;
    background-color: rgba(255, 255, 255, 0.9);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @include for-size(phone) {
      left: 0px;
      right: 0px;
      padding: 5px;
    }
  }

  &-player .player, &-player .mobile-player {
    border-radius: 20px;
  }
}

.job__beta {
  background: $main-active;
  position: absolute;
  left: 50px;
  top: 5px;
  padding: 5px 15px;
  border-radius: 4px;
}

.job__delay {
  background: map-get($color-list, "grey-70");
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 50%;
  z-index: 1;
  width: 201px;
  height: 201px;
  color: $text-video;
  font-weight: 500;
  font-size: 150px;
  line-height: 201px;
  text-align: center;
  text-transform: uppercase;
}

.job__info {
  &-header {
    position: fixed;
    // float:left;
    width:220px;
    top: 220px;
    min-height: 350px;
    @include for-size(phone) {
      min-height: 150px;
    }
    &-mobile {
      position: relative;
    }
  }
  &-idv {
    display: block;
    &-caption {
      border-radius: 5px;
      border-left: 2px solid #5A2AF1;
      background-color: rgba(90, 42, 241, 0.1);
      width: 100%;
      max-width: 600px;
      margin: 20px auto 35px auto;
      padding: 7px 18px;
      position: relative;
    }

    &-container {
      border-radius: 0px 20px 20px 0px;
      border-left: 2px solid #5A2AF1;
      background-color: rgba(90, 42, 241, 0.1);
      width: 100%;
      padding: 7px 18px;
      position: relative;
      margin-top: 40px;
      @include for-size(phone) {
        margin-top: 20px;
      }
    }

    &-title {
      font-family: 'Poppins', sans-serif;
      color: $grey-300;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
    }

    &-text {
      font-family: 'Poppins', sans-serif;
      color: #000000;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: justify;

      a {
        color: #000000 !important;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.status-job.preview.live {
  border: 1px solid #4DAE4B;
}