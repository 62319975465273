@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.security {
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0;
    padding: 25px 35px;
    box-shadow: 
      1px 1px 6px 0 rgba(98,110,130,0.11),
      0 0 25px 0 rgba(98,110,130,0.2);

    .button__group_center {
      button {
        min-width: 200px;

        @include for-size(tablet) {
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
      margin: 20px 0;
      @include for-size(tablet) {
        padding: 10px 15px;
      }
    }
  }

  &__title {
    line-height: 50px;

    @include for-size(tablet) {
      line-height: 30px;
    }
  }

  &__subtitle {
    padding: 10px 20px 10px 0;
    color: map-get($color-list, "black-80");
    white-space: pre-line;

    @include for-size(tablet) {
      word-break: break-word;
    }
  }

  &__subtitle-red {
    color: $main-red;
  }

  &__subtitle-green {
    color: $main-green;
    padding: 5px 0;
  }

  &__subtitle-grey {
    color: map-get($color-list, "grey-50");
  }

  &__content {
    padding-left: 15px;
    align-items: center;
  }

  &__link {
    color: map-get($color-list, "grey-50");
    text-decoration: underline;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  &__qrcode {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.padding {
      padding-bottom: 120px;

      @include for-size(tablet) {
        padding-bottom: 0;

        .security__error {
          margin-bottom: 0;
          height: 30px;
        }
      }
    }
  }

  &__input {
    margin-top: 109px;
    text-align: center;
    border-radius: 20px;
    background-color: #ffffff;
    border: 1px solid #BBC2C9;
    width: 50%;
    color: map-get($color-list, "black-80");
    font-size: 18px;
    padding: 4px 16px;

    &:focus {
      outline: none;
    }

    &.error {
      border-color: $main-red;
    }

    @include for-size(tablet) {
      margin-top: 20px;
      width: 85%;
    }
  }

  &__success {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;

    .security__success-content {
      flex: 1;
    }

    p {
      text-align: center;
    }

    button {
      min-width: 416px;
      margin: 29px 0;

      @include for-size(tablet) {
        min-width: 200px;
      }
    }
  }

  &__description {
    line-height: 21px;
  }

  &__input-signin {
    margin: 30px 0 0;
    width: 100%;
  }

  &__link {
    margin-top: 30px;
    text-decoration: underline;
  }

  &__error {
    color: $main-red;
    height: 20px;
  }

  &__urlError {
    display: flex;
    flex-direction: column;
    padding: 110px 50px;
    text-align: center;
  }
}
