.notification-item {
  border-left: none;
  border-radius: 0;
  box-shadow: none;

  .notification-message {
    font-size: 12px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button__without-styles.notification-button {
  font-size: 14px !important;
  margin: 0 10px;
  color: $blue-100;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
}

.notification-container-top-center {
  width: 100%;
  top: 80px;
  left: 0;
  right: 0;
}

.notification-close.notification-close {
  top: 50%;
  transform: translate(0, -50%);
  font-size: 18px;
  &:after {
    font-size: 18px;
  }
}

.notification-icon {
  margin-right: 5px;
}

.notification-icon-close {
  margin-left: 20px;
  margin-top: -5px;
}

.notification-custom-message {
  display: flex;
  flex-direction: row;
}

.notification-custom {
  display: flex;
  position: fixed;
  z-index: 100;
  top: 80px;
  min-height: 40px;
  padding: 10px calc(50% - 555px);
  justify-content: start;
  align-items: center;
  width: 100%;

  &.info {
    background-color: $main-active;
  }

  &.alert {
    background-color: #fc7979;
  }

  &.trial {
    background-color: map-get($color-list, "black-5");

    .notification-message {
      color: $main-link;
    }
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  .notification-message {
    color: #fff;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .notification-custom {
    padding: 10px;
  }
}

@media only screen and (max-device-width: 812px) and (orientation: portrait) {
  .notification-custom {
    padding: 10px;
    top: 60px;
  }
}
