@import '~assets/styles/abstracts/colors';

.billing-prices {
  &__title {
    margin: 40px 0 75px 0;
    font-size: 20px;
    line-height: 24px;
    color: $text-black;
    text-align: center;
  }

  &__col {
    margin-bottom: 30px;
  }

  &__text {
    padding: 80px 0 10px;
    font-size: 14px;
    line-height: 16px;
    color: $grey-900;
  }

  &__text-icon {
    margin-top: -10px;
    margin-right: 10px;

    g {
      fill: $grey-900;
    }
  }

  .button__group {
    padding: 20px 0 50px 0;
  }
}

