@import "~assets/styles/abstracts/mixins";
@import "~assets/styles/abstracts/colors";

.video__preview-title {
  margin-bottom: 15px;
  @include font-size(22, 25, 600);
}

.video__preview-subtitle {
  @include font-size(18);
}

.underline {
  text-decoration: underline;
}

.video__preview-item-title {
  height: auto;
  margin-bottom: 30px;
  @include font-size(22, 32, 400);
  color: $text-black;


  a{
    color: $blue-100;
    text-decoration: underline;
    cursor: pointer;
  }

  &.small {
    @include font-size(16);
  }

  &.question-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 34px;
    max-height: 64px; 
    &.small {
      line-height: 21px;
      max-height: 36px; 
      min-height: 36px; 
    }
  }
}

.video__preview-item-flex {
  display: flex;
  justify-content: space-between;

  .video__preview-item-suptitle {
    line-height: 30px;
  }

  .thinking {
    border-radius: 12.5px;
    line-height: 30px;
    padding: 0 10px;
    margin-bottom: 15px;
    svg {
      margin-right: 5px;
      margin-bottom: 2px;
      path {
        fill: map-get($color-list, "black-80");
      }
    }
    &.amber {
      background-color: map-get($color-list, "yellow-30");
    }
    &.green {
      background: #C1F0C0;
    }
  }
}

.video__preview-item-suptitle-container {
  display: flex;
  justify-content: space-between;
  .download {
    margin-right: 25px;

    svg {
      margin-left: 10px;
      margin-bottom: 5px;
    }

    &:disabled {
      cursor: not-allowed !important;
    }
  }
}

.video__preview-item-suptitle {
  margin-bottom: 15px;
  @include font-size(16, 19, 400);
  color: #656f8d;
  @media (max-width: 767px) {
    &.answer {
      display: none;
    }
  }
}

.video__preview-item-icon {
  margin-right: 10px;
}

.video__preview-item-player {
  margin-bottom: 25px;
  border-radius: 20px;
  position: relative;
}

.video__preview-button {
  @include align-center;

  .button {
    @include align-center;
    font-size: 22px;
  }
}

.video__preview-button {
  .button {
    color: $blue-600;
    background: none;
  }
}

.video__image {
  width: 100%;
}

.video__switch-camera {
  z-index: 1000;
  position: absolute;
  background: #fff;
  bottom: 12px;
  right: 12px;
  border-radius: 100%;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  height: 42px;
  width: 42px;
  align-items: center;
  transition: all 0.3s ease-in-out;
  font-size: 22px;
  i {
    color: map-get($color-list, "black-80");
    font-size: 16px;
  }
  &.devices {
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.audio__switch-camera {
  z-index: 1000;
  position: absolute;
  background: #fff;
  bottom: 12px;
  right: 80px;
  border-radius: 100%;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  height: 42px;
  width: 42px;
  align-items: center;
  transition: all 0.3s ease-in-out;
  font-size: 22px;
  i {
    color: map-get($color-list, "black-80");
    font-size: 16px;
  }
  &.devices {
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.video__camera-device {
  display: block;
  padding: 8px 12px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 8px;
}

.video__camera-device.active {
  background: #d6dbdf;
}

.video__camera-device:hover {
  background: #d6dbdf;
}

.video__camera-devices {
  z-index: 9999;
  position: absolute;
  right: 0px;
  bottom: 50px;
  color: #000;
  border-radius: 8px;
  background: #fff;
  overflow-y: auto;
  max-height: 160px;
  @media screen and (max-width: 767px) {
    left: 0px;
  }
}


.video-devices__container {
  position: relative;
  width: 50%;
  flex: 1;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}


.audio-devices__container {
  position: relative;
  width: 50%;
  flex: 1;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.video__audio-devices {
  z-index: 9999;
  position: absolute;
  left: 0px;
  bottom: 50px;
  width: 300px;
  color: #000;
  border-radius: 8px;
  background: #fff;
  overflow-y: auto;
  max-height: 160px;
  @media screen and (max-width: 767px) {
    left: 0px;
  }
}
