@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.header {
  background: $header-main;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1040;

  &__search {
    margin: 0;
    padding: 5px 30px;
    font-size: 14px;
    line-height: 16px;
    color: $main-white;
    border-radius: 14px;
    border: 1px solid $header-search-border;
    background: $header-search-bg;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $main-white;
    }
  }

  .navbar {
    height: 80px;
    padding: 0;

    .navbar-nav,
    .navbar-brand {
      height: 100%;
    }

    .navbar-brand {
      @include align-left-center;
      margin-right: 5rem;
    }

    .navbar-toggler {
      margin-left: auto;
    }

    .nav-item {
      padding-left: 0;
      padding-right: 40px;

      a, span {
        @include align-center;
        height: 100%;
        color: $main-white;
        position: relative;

        &:hover {
          text-decoration: none;
        }

        &.active {
          color: $purple-100;
  
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 4px;
            background: $purple-100;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }

    .navbar-collapse {
      height: 100% !important;

      @media (max-width: 991px) {
        margin-left: -15px;
        margin-right: -15px;
        background: $header-main;
        z-index: 2;

        .nav-item a,
        .nav-item span {
          padding: 20px 0;
          font-size: 14px;
          line-height: 16px;
          cursor: pointer;
        }

        .nav-item a:after {
          display: none;
        }
      }
    }
  }
}

.logo {
  @include align-left-center;
  height: 40px;
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@include for-size(phone) {
  .header {
    .navbar {
      height: 60px;
    }
  }

  .logo {
    height: 60px;
  }
}
