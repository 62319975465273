@import '~assets/styles/abstracts/colors';

.profile-card__list-title {
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;

  span {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: $grey-900;
  }
}