@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.search-container {
  padding: 5px;
  background-color: white;
  border-radius: 25px;
  border: 0.5px solid #BBC2C9;
  flex: 1;

  .row {
    margin-right: 0;
    margin-left: 0;

    @media (max-width: 1024px) {
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 991px) {
    .row {
      .bulk__button {
        text-align: inherit;
        padding: 12px 0 12px 30px;
      }
    }
  }
}

.row-search-container {
  background: $main-white;
  position: -webkit-sticky;
  position: sticky;
  top: 95px;
  z-index: 1;
  @include for-size(phone) {
    top: 75px;
  }
}

.row-search {
  align-items: center;
  background-color: $main-purple;
  border-radius: 25px;
  margin: 0 15px 20px;
  width: 100%;

  .rank-search {
    padding-left: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    @media (max-width: 912px) {
      justify-content: center;
    }
  }

  @media (max-width: 991px) {
    margin: 0 15px 20px;
    display: block;
    
    .label__search {
      height: 40px;
      line-height: 40px;
    }
    &.row {
      margin: 0 15px 20px;

      .rank-search {
        flex: inherit;
        max-width: inherit;
        padding-right: 0;
      }

      .ranking-search {
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 0;

        .button__group_left button {
          margin: 0;
          position: absolute;
          top: -85px;
          right: 15px;
        }
      }
    }
  }
}

.form__search_name {
  width: 100%;
  max-width: 250px;
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    max-width: initial;
  }
}

.label__search {
  margin-bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
  color: $main-white;

  &.loader-search {
    margin: 4px 20px 0;
  }
}

.name-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
}

.name-search__title {
  @include align-left-center;
  min-width: 55px;
  margin-right: 16px;
}

.name-search__input {
  padding: 12px 16px;
  border-radius: 20px;
  background-color: $main-white;
  border: 1px solid map-get($color-list, "grey-50");
}

.name-search__input::placeholder {
  color: rgba(118,132,147,0.5);
}

.bulk__button {
  padding: 12px 0 12px 50px;
  color: map-get($color-list, "grey-100");

  svg {
    margin-right: 5px;
  }

  &:hover {
    color: map-get($color-list, "black-80");

    svg path {
      fill: map-get($color-list, "black-80");
    }
  }
}
