@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.comapany__job-element {
  margin-bottom: 20px;
  padding: 25px;
  background-color: $main-white;
  border-radius: 2px;
  box-shadow: 
    1px 1px 6px 0 rgba(98,110,130,0.11),
    0 0 25px 0 rgba(98,110,130,0.2);
}

.comapany__job-element-header {
  @include align-center-center;
  margin-bottom: 30px;
}

.comapany__job-link {
  &:hover {
    color: $grey-800;
  }
}

.comapany__job-element-title {
  @include font-size(16, 16);
  color: $grey-800;
}

.comapany__job-element-body {
  @include align-left-center;
  flex-wrap: wrap;
}

.comapany__job-element-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 50%;
  margin-bottom: 10px;
}

.comapany__job-element-text {
  margin-right: 5px;
  @include font-size(12, 16);
  color: $grey-600;
  text-transform: uppercase;
}

.comapany__job-element-data {
  
}