.input-inline {
  display: inline-block;
}

.input-checkbox {
  width: auto;
}

.label-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  font-size: 14px;
  line-height: 18px;
  user-select: none;
  cursor: pointer;

  .input-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $main-white;
    border: 2px solid rgba($main-grey, 0.5);
  }

  .active {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 6px;
      top: 0px;
      width: 5px;
      height: 13px;
      border: solid $main-color;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
