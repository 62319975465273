@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.billing-info {
  margin: 25px 0;
  padding: 0 20px;
  border-right: 1px solid #E4E8F0;
  color: #1E384B;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__list {
    margin-bottom: 20px;
    text-align-last: left;
    line-height: 20px;
    font-weight: 300;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    text-align: left;
  }

  &__icon {
    margin-right: 5px;
  }

  &__caption-text {
    @include font-size(12, 14, 300);
    margin-bottom: 5px;
  }

  &__caption-small-text {
    @include font-size(11, 13, 300);
    margin-bottom: 5px;
    margin-top: 20px;
  }

  &__image {
    display: flex;
  }
}

.billing__offset-left {
  margin-left: 40px;
}

.billing__offset-bottom {
  margin-bottom: 16px;
}
