@import "~assets/styles/abstracts/colors";

.hamburger {
  width: 30px;
  height: 30px;
  position: relative;
  -webkit-appearance: inherit;

  &__open {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  > span {
    width: 100%;
    height: 2px;
    background: $main-link;
    position: absolute;

    &:nth-child(1) {
      top: 5px;
      left: 0;
    }

    &:nth-child(2) {
      top: 15px;
      left: 0;
    }

    &:nth-child(3) {
      top: 25px;
      left: 0;
    }
  }
}
