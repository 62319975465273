@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.input__radio {
    @include align-left-center;
}

.input__radio-item:checked,
.input__radio-item:not(:checked) {
    position: absolute;
    left: -9999px;
}
.input__radio-item:checked + label,
.input__radio-item:not(:checked) + label
{
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    line-height: 24px;
    display: inline-block;
    color: #666;
}
.input__radio-item:checked + label:before,
.input__radio-item:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid $main-grey;
    border-radius: 100%;
    background: $main-white;
}
.input__radio-item:checked + label:after,
.input__radio-item:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: $main-grey;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.input__radio-item:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.input__radio-item:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.input__radio-label {
    margin-bottom: 16px;

    @include for-size(phone) {
       margin-bottom: 8px;
    }
}
