@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.comments__item {
  background: map-get($color-list, "black-5");
  border-radius: 20px;
  padding: 15px 16px 5px;

  &-buttons {
    padding-right: 10px;
    line-height: 16px;
    display: none;
    &.visible {
      display: block;
      padding-right: 0;
    }
    button svg g {
      fill: map-get($color-list, "black-50");
    }
  }

  &:hover {
    .comments__item-buttons {
      display: block;
      &-red:hover {
        svg g {
          fill: $main-red;
        }
      }
      &-purple:hover {
        svg g {
          fill: $main-purple;
        }
      }
    }
  }
}

.comments__item-header {
  margin-bottom: 10px;
}

.comments__item-header-block {
  @include align-center-center;
  .rc-steps-item-title {
    line-height: 22px;
    width: auto;
  }
}

.comments__item-icon {
  width: 16px;
  height: 16px;
  margin-left: 13px;

  &.disabled {
    * {
      fill: $grey;
    }
  }
}

.commtents__item-body {
  position: relative;
  margin-left: -5px;
  &.editable {
    .comments__item-text {
      border: 1px solid map-get($color-list, "black-5");
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      min-height: 40px;
      background-color: $main-white;
      &:focus {
        outline: none;
        border: 1px solid map-get($color-list, "purple-100");
      }
    }
    &.with-warning .comments__item-text:focus {
      border: 1px solid $main-red;
    }
  }
}

.comments__item-text {
  width: 100%;
  height: 20px;
  min-height: 20px;
  padding: 5px;
  background: none;
  box-shadow: none;
  border: none;
  @include font-size(14,22);
  color: $main-grey;
  -webkit-text-fill-color: $main-grey;
  opacity: 1;
  resize: none;
  overflow: auto;
}

.rc-steps-comment {
  .rc-steps-item-description {
    padding: 5px 0 0 15px;
  }
  .rc-steps-item-content {
    margin-top: -10px;
  }
}