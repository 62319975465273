.modal-dialog {
  margin: .5rem auto;
  
  &.billing-confirm {
    max-width: 520px;
    margin-top: 240px;

    @include for-size(phone) {
      max-width: 300px;
      margin-top: 100px;
    }

    &__left,
    &__right {
      width: 50%;
    }

    .modal-content {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      border-radius: 20px;
      border: none;
      overflow: hidden;
    }
    
    .modal-header,
    .modal-footer {
      border: 0; 
      .button__group {
        width: 100%;
      }
    }

    .modal-header {
      padding-top: 10px;
      padding-bottom: 10px;

      .close {
        &:focus {
          outline: none;
        }
      }
    }

    .modal-body {
      display: flex;
      padding: 0;
      overflow: hidden;

      .billing-info {
        width: 50%;
      }

      @include for-size(tablet) {
        flex-wrap: wrap;

        .billing-info {
          order: 2;
        }

        .billing-checkout {
          order: 1;
        }
      }

      & > div {
        @include for-size(tablet) {
          width: 100%
        }
      }

      .checkout {
        padding: 0;
        border: none;
      }
    }
  }
}