@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.modal-dialog {
  margin: .5rem auto;
  
  &.reject-details {
    margin-top: 40px;

    @media (min-width: 425px) {
      margin-top: 140px;
    }
  
    .modal-body {
      padding: 0 20px;

      @media (min-width: 425px) {
        padding: 0 60px;
      }
    }
  }
}

.reject-details__buttons {
  padding: 35px 0 25px 0;
}

.candidate-details {
  .modal-body {
    padding: 30px 50px;
  }
}

.form__candidate-details-title {
  margin-bottom: 40px;
  @include font-size(22);
  text-align: center;
  color:#34495E;
}

.form__candidate-details-subtitle {
  margin-bottom: 40px;
  @include font-size(16);
  text-align: center;
}

.form__candidate-details-button {
  padding: 25px 0;
  text-align: center;

  .button {
    max-width: 180px;
    font-size: 22px;
  }
}

.form__reject-details-button {
  margin: 16px 0;
  display: flex;
  justify-content: center;
}