.dropdown__custom {
  padding-bottom: 0;
  line-height: 1;

  .dropdown__custom-icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  
    & g {
      fill: rgba(0, 0, 0, 0.847);
    }
  }

  .dropdown-menu__custom {
    min-width: 140px;
    padding: 5px;

    @include for-size(phone) {
      min-width: 8rem;
    }
  
    .dropdown-item__custom {
      padding: 0 5px;
    }
  
    .dropdown-item__custom-text {
      padding: 0 5px;
    }
  
    .dropdown-item__custom {
      padding: 0 5px 5px 5px;
    }
    
    .dropdown-divider__custom {
      margin: 5px 0;
    }
  }
}

.dropdown__language {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}

.dropdown__language-menu {
  padding: 16px 0;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 5px map-get($color-list, "black-80");
  
  &.dropdown-menu {
    max-width: 205px;
  }

  @media (max-width: 1400px) and (min-width: 993px) {
    left: auto;
  }
  @media (max-width: 1190px) and (min-width: 1025px) {
    left: auto !important;
  }

  .dropdown__language-item {
    &.dropdown-item {
      padding: 8px 24px;
      font-size: map-get($font-sizes, "small");
      line-break: map-get($font-sizes, "small") * 1.2;
      color: map-get($color-list, "grey-100");
    }
  }
}

.dropdown__language-btn {
  color: $main-link;
  
  &:hover {
    color: $main-link !important;
  }

  &.btn-primary.dropdown-toggle {
    width: 100%;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    justify-content: center;
    background: none;
    box-shadow: none !important;
    border: none;

    &:active,
    &:active:focus {
      box-shadow: none;
      background: none;
      border: none;
    }
  }
}