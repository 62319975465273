@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.header-preview__header {
  width: 100%;
  max-width: 600px;
  margin: 30px auto 15px auto;
  padding-bottom: 15px;
  border-bottom: 1px solid #98A2AD;
}

.header-preview__icon {
  margin-right: 10px;
}

.header-preview__header-title {
  display: flex;
  margin-bottom: 20px;
}

.header-preview__header-title-text {
  margin-right: 40px;
  @include font-size(24,26,600);
  color: $text-black;
}

.header-preview__header-title-icon {
  width: 100%;
  @include font-size(30,1);
}

.header-preview__additional-info {
  @include align-bottom-center;
  @include font-size(16,26);
  color: #768493;

  div {
    width: 80%;
    p {
      max-width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


.header-preview__comapny-name {
  @include font-size(16,26,600);
}

.header-preview__company-location {
  @include font-size(14,26);
}

.header-preview__additional-info-icon {
  margin-right: 5px;
}
