@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.select {
  .select__menu {
    z-index: 2;
  }
}

.select__without-styles {
  width: 100%;

  .select__menu {
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 10px;
    overflow: hidden;
  }

  .select__option {
    padding: 8px 16px;
    border-radius: 10px;

    &:hover {
      background-color: map-get($color-list, "black-5") !important;
    }
  }
  
  .select__control {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    &:hover {
      border-color: transparent;
    }

    &--menu-is-focused {
      box-shadow: none;
      border-color: transparent;
    }
  }

  .select__indicator-separator {
    background-color: transparent;
  }

  .select__dropdown-indicator {
    padding-left: 4px;
    padding-right: 4px;
  }

  .select__value-container {
    padding-left: 0;
  }
}