@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.invite-info__header {
  @include align-left-center;
  flex-wrap: wrap;
  padding: 40px 0 30px 0;
  
}

.invite-info__header-title {
  font-size: 20px;
  line-height: 26px;
}

.invite-info__additional-info {
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: $main-grey;
}

.invite-info__additional-info-company {
  margin-right: 20px;
}

.invite-by-link {
  @include align-center;
  flex-wrap: wrap;
  margin-bottom: 40px;

  .invite-by-link__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 4px 4px 16px;
    font-size: 14px;
    line-height: 16px;
    background: #ffffff;
    border: 1px solid rgba(118, 132, 147, 0.5);
    border-radius: 20px;
    box-sizing: border-box;
  }

  .invite-by-link__link-title {
    width: 100%;
    margin-bottom: 10px;
    @include font-size(16, 19, 500);
    color: $main-grey;
  }
  
  .invite-by-link__link {
    display: block;
    width: 400px;
    margin-right: 20px;
    font-size: 16px;
    line-height: 19px;
    color: $text-black;
    text-decoration: underline;
    text-overflow: ellipsis;
    overflow: hidden;

    .button {
      @include font-size(14, 14);
    }

    @include for-size(phone) {
      width: 240px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .btns__wrapper {
    margin-left: 80px;
  }
}

.invite-info__copy-icon {
  width: 25px;
  height: 15px;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
}

.invite-info__counter-icon {
  width: 25px;
  height: 15px;
  margin-right: 10px;
}

.invite-info__candidates-row {
  margin-bottom: 15px;

  &.last {
    margin-bottom: 40px;  
  }
}

.invite-info__candidates-text {
  color: $main-grey;
  text-transform: uppercase;
  &.none-transform {
    text-transform: none;
  }
}

.invite-info__candidates-number {
  color: $main-black;
  text-transform: none;
}

.invite-info__error {
  @include align-left-top;
  @include font-size(18, 22,600);
  color: $main-red;
  text-align: center;
}

.invite-info__error-list {
  width: 100%;
  text-align: left;
}

.invite-info_error-title {
  margin-right: 5px;
}

.invite-info__error-list-item {
  padding: 0;
}

.invite-info__buttons {
  margin: 40px 0;

  .button {
    @include font-size(22,25, 600);
  }
}
