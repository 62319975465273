@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.form-description {
  margin-bottom: 60px;
}

.text-editor {
  padding-top: 30px;
  padding-bottom: 22px;
  position: relative;

  &:focus {
    border-color: map-get($color-list, "purple-100");
  }
}

.text-editor__label {
  display: block;
  margin-bottom: 8px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 16px;
  color: $text-black;
}

.text-editor__error {
  font-size: 12px;
  line-height: 14px;
  color: $main-red;
  position: absolute;
  left: 20px;
  bottom: 0
}

.text-editor__main-body {
  border-radius: 20px;
  border: 1px solid map-get($color-list, "grey-30");

  &.error {
    border-color: $main-red;
  }
}

.text-editor__toolbar {
  @include align-center;
  margin: 0;
  padding: 4px 0;
  border: none;
  border-radius: 20px 20px 0 0;
  background-color: map-get($color-list, "grey-30");

  .rdw-dropdown-wrapper {
    height: 22px;
  }

  > * {
    margin: 0
  }
}

.text-editor__editor {
  height: 350px;
  background: $main-white;
  border-radius: 0 0 20px 20px;

  .public-DraftEditor-content {
    padding: 12px 16px;
  }

  * {
    margin: 0;
    font-family:"Poppins", sans-serif !important;
    font-size: 14px;
    line-height: 21px;
    color: $main-black;

    .public-DraftStyleDefault-ltr {
      text-align: inherit;
    } 
  }

  a {
    * {
      color: $secondary-blue !important;
    }
  }

  @include generate-headers-with-children();

  ol, ul {
    li {
      display: list-item;
      margin: 0;
      padding: 0;
    }
  }
}

.text-editor__inline > div, 
.text-editor__list > div,
.text-editor__align > div {
  background-color: transparent;
  border: none;
}

.tox-tinymce {
  border-radius: 20px !important;
}

.text-editor-wrapper {
  &.active {
    & > .tox.tox-tinymce {
      border-color: map-get($color-list, "purple-100") !important;
      border-width: 1px;
    }
  }

  &.error {
    & > .tox.tox-tinymce {
      border: 1px solid #EB3323 !important;
    }
  }

  & .tox-toolbar__primary {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#text-editor {
  display: none;
}

.text-editor-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
}