@import "~assets/styles/abstracts/mixins";
@import "~assets/styles/abstracts/colors";

.page-content {
  &.preview,
  &.common-job {
    .button__group_center {
      margin-top: 35px;
      margin-bottom: 0px;

      .main-link {
        margin: 0px 25px 20px;
        min-width: 220px;
      }
    }
  }
}

.details-player {
  @include align-center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .vjs-modal-dialog {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &-own {
    display: block;

    .video-js {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.react-player {
  margin-bottom: 20px;
  position: relative;

  video {
    border-radius: 0;
    background-color: $grey-300;

    &:focus {
      outline: none;
    }
  }

  .vjs-control-bar {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.details-job {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  @include font-size(14);

  p {
    display: flex;
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .details-job__title {
    margin-right: 5px;
    color: $main-grey;
  }

  .details-job__link {
    display: block;
    white-space: nowrap;
  }

  .details-job__data,
  .details-job__link {
    font-weight: 600;
    color: $text-black;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.details-job__icon {
  margin-right: 10px;
}

.details-text__box {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 25px auto;

  @include generate-headers-with-children();

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    min-height: 20px;

    span {
      background: none !important;
    }
  }

  p {
    min-height: 17px;
  }

  a {
    color: #49C7ED!important;
    text-decoration: underline!important;
    cursor:pointer!important;
  }

  * {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: $main-black;
  }
  ul,
  ol {
    padding-left: 20px !important;

    li {
      display: list-item;
      margin-bottom: 5px;
      padding: 0;

      span {
        background: none !important ;
      }
    }
  }
}
