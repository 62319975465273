@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.profile-card__footer {
  padding: 15px 0;
  text-align: center;
  background: $card-gradient; 

  &_text {
    font-size: 20px;
    letter-spacing: 2.25px;
    line-height: 24px;
    color: $main-white;
    text-transform: uppercase;


    .price,
    .period {
      padding: 0 3px;
      letter-spacing: 1px;
      text-transform: initial;
    }

    .price {
      font-size: 24px;
      font-weight: 600;	
      line-height: 28px;
    }

    .period {
      font-size: 13px;
      font-weight: 300;
      line-height: 11px;
    }
  }
}