@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.dashboard {
  .progress__wrapper {
    .progress {
      margin-top: 30px;
    }
  }
}

.dashboard__header-title {
  margin-top: 55px;
  margin-bottom: 20px;
}

@media only screen and (orientation: portrait) {
  .dashboard__header-title {
    margin-top: 75px;
  }
}

.dashboard__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0;
  padding: 15px 25px;
  min-height: 240px;
  background: $header-main;
  box-shadow: 
    1px 1px 6px 0 rgba(98,110,130,0.11),
    0 0 25px 0 rgba(98,110,130,0.2);

  .progress__pannel {
    margin-bottom: 0;
  }

  .progress__title {
    margin-bottom: 0;

    h2 {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      color: $main-grey;
    }
  }

  .progress__left-children-container {
    margin-bottom: 0;
  }
}

.dashboard__card-text,
.dashboard__card-number {
  width: 100%;
}

.dashboard__card-title {
  margin-bottom: 5px;
  color: $main-grey;
} 

.dashboard__card-number {
  color: $main-grey;
}

.dashboard__card-links {
  text-align: right;

  a {
    display: block;
    width: 100%;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
