@import "~rc-steps/assets/index.css";
@import "~rc-steps/assets/iconfont.css";
@import "~react-phone-number-input/style.css";
@import "~react-nice-input-password/dist/react-nice-input-password.css";
@import "~react-notifications-component/dist/theme.css";

// Vendors
@import "./vendors/bootstrap.scss";

// Abstracts
@import "./abstracts/mixins.scss";
@import "./abstracts/colors.scss";

// Base
@import "./base/normalize.scss";
@import "./base/common.scss";

// Components
@import "./components/add-new-user.scss";
@import "./components/add-user-baner.scss";
@import "./components/buttons.scss";
@import "./components/breadcrumbs.scss";
@import "./components/checkbox.scss";
@import "./components/credit-cards.scss";
@import "./components/color-picker.scss";
@import "./components/countdown-timer.scss";
@import "./components/card.scss";
@import "./components/change.scss";
@import "./components/dropdown.scss";
@import "./components/notification.scss";
@import "./components/modals.scss";
@import "./components/profile.scss";
@import "./components/links.scss";
@import "./components/logo-upload.scss";
@import "./components/icons.scss";
@import "./components/form.scss";
@import "./components/social-login.scss";
@import "./components/status-job.scss";
@import "./components/select.scss";
@import "./components/steps.scss";
@import "./components/steps-questions";
@import "./components/steppers.scss";
@import "./components/video.scss";
@import "./components/dropzone.scss";
@import "./components/onboarding.scss";
@import "./components/plan-upgrade.scss";

// Common
@import "./common/add-more.scss";
@import "./common/card.scss";
@import "./common/details.scss";
@import "./common/message.scss";
@import "./common/message-upload-video.scss";
@import "./common/hamburger.scss";
@import "./common/table.scss";
@import "./common/tooltip.scss";
@import "./common/texteditor.scss";
@import "./common/spinner.scss";
@import "./common/popover.scss";
@import "./common/select.scss";
@import "./common/footer.scss";

// Common Headers
@import "./common/header/candidate.scss";
@import "./common/header/candidate-job.scss";
@import "./common/header/main.scss";
@import "./common/header/main-menu.scss";
@import "./common/header/preview.scss";
@import "./common/header/job.scss";

// Common Forms
@import "./common/forms/input.scss";
@import "./common/forms/select.scss";
@import "./common/forms/radio.scss";
@import "./common/forms/radio-date.scss";
@import "./common/forms/radio-rating.scss";
@import "./common/forms/rating-search.scss";
@import "./common/forms/personalized-invites.scss";
@import "./common/forms/textarea.scss";
@import "./common/forms/name-search.scss";

// Common Profile
@import "./card/card.scss";
@import "./card/card-ava.scss";
@import "./card/card-body.scss";
@import "./card/card-edit.scss";
@import "./card/card-title.scss";
@import "./card/card-header.scss";
@import "./card/card-footer.scss";
@import "./card/card-list-title.scss";
@import "./card/card-list.scss";

// Common Progress
@import "./common/progress/line.scss";
@import "./common/progress/panel.scss";

// Comments
@import "./comments/main.scss";
@import "./comments/item.scss";
@import "./comments/add-comment.scss";

// Confiramtion
@import "./confirmation/confirm.scss";
@import "./confirmation/company-delete.scss";
@import "./confirmation/subscription.scss";

// Candidates
@import "./candidates/list.scss";
@import "./candidates/list-column.scss";
@import "./candidates/list-item.scss";

// Graphs
@import "./graphs/candidates.scss";

// Modals
@import "./modals/reject.scss";
@import "./modals/details.scss";
@import "./modals/share.scss";
@import "./modals/error.scss";

// Invites
@import "./invites/all.scss";
@import "./invites/info.scss";

// Companies
@import "./companies/main.scss";
@import "./companies/overview.scss";
@import "./companies/job.scss";
@import "./companies/create.scss";
@import "./companies/card.scss";
@import "./companies/edit.scss";

// Billing
@import "./billing/main.scss";
@import "./billing/add-new-card.scss";
@import "./billing/checkout.scss";
@import "./billing/show-card.scss";
@import "./billing/prices.scss";
@import "./billing/info.scss";

// Job
@import "./job/questions.scss";
@import "./job/preview.scss";
@import "./job/overview.scss";

// Dashboard
@import "./dashboard/main.scss";

// Connect
@import "./connect/main.scss";

// Security
@import "./security/main.scss";

// Views
@import "./views/documents.scss";
@import "./views/tips.scss";

@import "./abstracts/variables.scss";
