@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.tips__player-wrapper {
  position: relative;
  padding-top: 56.35%;
}

.tips__player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  iframe {
    border-radius: 24px;
    box-shadow: 2px 3px rgba(0, 0, 0, 0.05);
  }
}

.tips__list,
.tips__player {
  &-title {
    margin-bottom: 10px;
    @include font-size(16, 26, 600);
    text-align: center;
  }
}

.tips__player-title {
  margin-bottom: 20px;
  @include font-size(24, 30, 400);
}

.tips__player-info {
  margin-top: 35px;
  margin-bottom: 35px;
}

.tips__list {
  margin-bottom: 35px;
}

.tips__list-item {
  margin-bottom: 15px;
  padding: 0;
  @include font-size(14);
  color: $blue-700;

  &__last {
    margin-bottom: 0;
  }
}

.tips {
  .button__group {
    @include align-top;
    flex-wrap: wrap;
    margin-bottom: 60px;

    .button__wrapper {
      max-width: 100%;
      text-align: center;

      &.upload {
        margin-bottom: 25px;
      }
    }

    @media (min-width: 430px) {
      flex-wrap: nowrap;
    }
  }

  .button__medium-large_hollow {
    background: $main-white;
  }
}


