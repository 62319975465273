@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.candidates__list-column-title {
  margin-bottom: 15px;
  @include font-size(12,20);
  text-transform: uppercase;
  color: $main-grey;
  &.cursor {
    cursor: pointer;
  }
  font-weight: 500;
}