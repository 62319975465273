@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.input__date-radio {
    @include align-left-center;
    display: inline-flex;
    margin-top: 15px;
    margin-right: 10px;
}

.input__date-radio-item:checked,
.input__date-radio-item:not(:checked) {
    position: absolute;
    left: -9999px;
}

.input__date-radio-item:checked + label,
.input__date-radio-item:not(:checked) + label
{
    display: inline-block;
    padding: 8px 12px;
    cursor: pointer;
    line-height: 24px;
    @include font-size(14, 15, 500);
    border: 1px solid $main-active;
    border-radius: 15px;
    color: $main-active;
}

.input__date-radio-item:checked + label {
    background: $main-active;
    border: 1px solid $main-white;
    color:  $main-white;
}

.input__date-radio-label {
    margin-bottom: 10px;

    @media (min-width: 425px) {
        margin-bottom: 20px;
    }
}
