.billing {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &__loader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
};