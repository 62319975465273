@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.textarea {
  margin-bottom: 25px;
  text-align: left;
  position: relative;

  label {
    margin: 0;
    padding: 0;

    &.label__question {
      padding-left: 34px;

      &.disabled {
        padding-left: 38px;

        @include for-size(tablet) {
          padding-left: 14px;
        }
      }

      @include for-size(tablet) {
        padding-left: 10px;
      }

      span {
        font-size: 12px;
        color: $grey;
        line-height: 13px;
      }
    }
  }

  .invalid {
    border-color: $main-red;
  }
}

.textarea__header {
  flex-wrap: wrap;
  display: flex;
  padding: 25px 0;
  @media (max-width: 500px) {
    flex-direction: column;
  }

  .input {
    flex: 1;
    max-width: 47%;
    min-width: 47%;
    margin: 0 1.5%;

    & .input-wrapper:has(div.invalid) {
      padding-bottom: 30px !important;

      & .input-error-message {
        bottom: auto;
        margin-top: 2px;
      }
    }

    & .input-wrapper:has(div.select--is-disabled) {
      cursor: not-allowed;
    }

    &.thinking {
      max-width: 22%;
      min-width: 22%;
      label {
        min-height: 18px;
      }
      @media (max-width: 500px) {
        min-width: 210px;
        max-width: inherit;
        label {
          min-height: auto;
        }
      }
    }
    &.max-count {
      .input-element {
        padding: 10px 16px;
      }
    }
    @media (max-width: 500px) {
      min-width: 210px;
      max-width: inherit;
    }
    i {
      color: map-get($color-list, "black-50");
    }
    .label {
      font-size: 12px;
      color: map-get($color-list, "black-100");
      margin-bottom: 5px;
      svg path {
        fill: $purple-100;
      }
    }
  }
}

.textarea__text {
  font-size: map-get($font-sizes, 'extra-small');
  color: $grey;
}

.textarea__inner {
  display: flex;
  align-items: center;
  position: relative;
  background-color: $secondary-brand;
  border-radius: 21px;

  textarea {
    resize: none
  }

  span {
    font-size: 25px;
    line-height: 37px;
    color: map-get($color-list, "black-50");
    text-align: center;
    min-width: 50px;
  }

  .text-editor {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
  .text-editor__editor, .rdw-link-modal-target-option {
    margin-bottom: 0;
    height: 106px;
    span, .rdw-link-modal-btn {
      font-size: 14px;
      line-height: 21px;
      color: $main-black;
      text-align: start;
      min-width: auto;
    }
  }
}

.rdw-link-modal {
  border-radius: 20px;
  .rdw-link-modal-label {
    font-size: 12px;
    color: $grey;
    line-height: 13px;
  }
  .rdw-link-modal-input {
    font-size: 14px;
    line-height: 17px;
    border: 1px solid map-get($color-list, "grey-50");
    border-radius: 20px;
    padding: 15px 10px;
  }
  .rdw-link-modal-target-option {
    display: none;
  }
  .rdw-link-modal-btn {
    border-radius: 22px;
    font-size: 16px;
    border-color: $main-purple;
    color: $main-purple;
    &:first-child {
      background-color: $main-purple;
      color: $main-white;

      &:disabled {
        background: map-get($color-list, "grey-30");
        border-color: map-get($color-list, "grey-30");
      }
    }
    &:active {
      box-shadow: none;
    }
  }
}

.textarea-error-message {
  font-size: map-get($font-sizes, "extra-small");
  line-height: map-get($font-sizes, "extra-small") * 1.2;
  color: $main-red;
  position: absolute;
  bottom: -22px;
  left: 16px;
}

.textarea__element {
  width: 100%;
  padding: 12px 45px; 
  font-size: 14px;
  line-height: 16px;
  border: 1px solid rgba($main-grey, 0.5);
  border-radius: 20px;
  box-sizing: border-box;

  &_overview {
    padding-left: 16px;
    padding-right: 16px;
  }

  &:focus {
    border-color: map-get($color-list, "purple-100");
  }

  &:disabled {
    color: $main-grey;
  }

  &:focus {
    outline: none;
  }
}
