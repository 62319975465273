@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.footer {
  &__wrapper {
    align-items: center;
    background: $footer-main;
  }


  &__block {
    @include align-left-center;
  }
  
  &__column {
    @include align-center;
    height: 100px;
  
    &_left {
      @include align-left-center;
    }

    &_center {
      @include align-right-center;
    }
  }

  &__image-wrapper {
    width: 50px;
    margin-right: 15px;
    flex-shrink: 0
  }

  &__logo {
    width: 100%;
  }

  &__text {
    font-size: 12px;
    line-height: 14px;
    color: $main-link;
  }
  
  &__list {
    li {
      padding-left: 12px;
      padding-right: 12px; 
    }

    a {
      font-size: 12px;
      line-height: 14px;
      color: $main-link;
    }
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@include for-size(desktop) {
  .footer {  
    &__block {
      @include align-center;
      flex-wrap: wrap;
    }
    
    &__image-wrapper {
      width: 100%;
      margin-right: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__logo {
      width: 50px;
      margin-top: 0;
      margin-bottom: 10px;
    }

    &__column {
      &_left {
        justify-content: center;
        order: 1;
      }
  
      &_center {
        justify-content: center;
        order: 2;
      }
    }

    &__text {
      width: 100%;
      max-width: 220px;
      text-align: center;
    }

    &__list {
      li {
        width: 100%;
        padding: 0;
        text-align: center;
      }
    }
  }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@include for-size(phone) {
  .footer {
    &__column {
      height: auto;
      padding-top: 20px;
    }

    &__column:last-child {
      padding-bottom: 30px;
    }

    &__list {
      li {
        a {
          font-size: 12px;
          line-height: 14px;
          color: $main-link;
        }
      }
    }
  }
}