@import '~assets/styles/abstracts/colors';
@import '~assets//styles/abstracts/mixins';

.onboarding {
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
  padding: 10px 20px;
  background: $main-white;
  box-shadow: 
    1px 1px 6px 0 rgba(98,110,130,0.11),
    0 0 25px 0 rgba(98,110,130,0.2);

  &__title {
    margin-bottom: 5px;
  }

  &__subtitle {
    margin-bottom: 15px;
  }

  &__list-image {
    margin-right: 15px;
  }

  &__list-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    padding: 0;
  }
}

.onboarding__right {
  display: flex;
  align-items: center;
  position: relative;

  div {
    display: flex;
    padding-right: 30px;

    @include for-size(phone) {
      display: none;
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
  }
}