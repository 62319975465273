@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.candidate__comment {
    &-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        color: #59595980;
        font-size: 12px;
        margin-bottom: 5px;

        & .author {
            color: $seconday-grey;
            margin: 0;
            word-wrap: break-word;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            width: auto !important;
            max-width: 180px !important;
            white-space: initial;
        }

        & .toggle-menu {
            border-radius: 50%;

            &:hover, &:focus, &:focus-within, &:active {
                background-color: #5A2AF11A;
            }
        }

        & .toggle-menu__content {
            z-index: 10;
            width: 140px;
            border-radius: 4px;
            box-shadow: 0px 4px 4px 0px #0000000D;
            border: 1px solid #5959591A;
            right: 0;
            top: 20px;

            &-item {
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: map-get($color-list, "black-dark");
                padding: 8px;

                & svg path {
                    fill: map-get($color-list, "black-dark");
                }

                &:hover {
                    background-color: #5A2AF11A;
                }

                &.edit {
                    border-bottom: 1px solid #5959591A;
                }
            }
        }
    }
}

.candidate__sidebar-commentlist {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    gap: 32px;
}

.candidate__sidebar-comments__header {
    font-size: 18px;
    color: #595959;
    font-weight: 500;
    padding: 16px 0px;
}

.candidate__comment-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    border-bottom: 1px solid #5959591A;
    padding-bottom: 12px;

    &-avatar {
        width: 26px;
        height: 26px;
        min-width: 26px;
        margin-right: 0px;
    }
}

.candidate__comment-icons {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    svg {
        cursor: pointer;
    }
}

.candidate__comment-textarea {
    margin-top: 0px;
    min-height: 100px;
    width: 100%;
    min-height: 20px;
    margin: 0;
    color: $main-grey;
    @include font-size(14);
    resize: none;
    border: 1px solid map-get($color-list, "black-5");
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 10px 15px;
    background-color: #5959591A;

    &-input {
        outline: none;
        border: none;
        height: 100%;
        width: 100%;
        padding: 10px;
        background-color: transparent;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: map-get($color-list, "black-dark");
        resize: none;
    }

    &.active {
        outline: none;
        border-color: map-get($color-list, "purple-100");
    }

    &.with-warning-message {
        border-color: $main-red;
    }
}

.candidate__sidebar-container {
    overflow-y: auto;
    height: inherit;

    @media (max-width: 767px) {
        overflow-y: scroll;
    }

    &::-webkit-scrollbar {
        border: none;
        height: 3px;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $main-white;
        border-radius: 12px;
        margin-left: -5px;
        background: $purple-200;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: $purple-100;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $purple-100;
    }

    @media (max-width: 1023px) {
        padding: 0;
    }
}

.candidate__sidebar-comments {
    padding: 12px 22px;

    @media (max-width: 1023px) {
        padding: 12px 11px;
    }

    .add-comment__warning-message {
        padding-top: 4px;
    }

    .form__add-comment-submit {
        padding-top: 8px;
    }
}