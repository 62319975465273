@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.header-job__header {
  padding: 30px 0;

  @include for-size(phone) {
    .header-job__header-title {
      justify-content: space-between;
      .status-job {
        margin: 0;
      }
    }

    .header-job__additional-info {
      display: flex;
      justify-content: space-between;
      color: $main-link;
    }
  }
}

.header-job__header-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  line-height: 1;
}

.header-job__header-title-text {
  margin-right: 18px;
  font-weight: map-get($font-weights, "semi-bold");
  color: map-get($color-list, 'black-80')
}

.header-job__header-title-icon {
  width: 100%;
  // @include font-size(30,1);
}

.header-job__additional-info {
  // @include font-size(16,26);
  // color: #768493;
  &-company {
    color: $main-link;
  }
}

.header-job__additional-info-icon {
  width: 12px;
  margin-right: 4px;
}

.header-job__additional-info-location {
  margin-left: 16px;
}

.header-job__additional-info-location, .header-job__additional-info-company {
  display: inline-block;
  max-width: 45%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-job__wrapper {
  display: flex;
  flex-direction: column;

  font-family: 'Poppins';
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  color: $main-grey;
}