.document {
  max-width: 800px;
  padding: 35px 0;

  h1 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
  }

  h2 {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  p {
    margin-bottom: 20px;
    line-height: 20px;

    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
}