@import '~assets/styles/abstracts/colors';

.select, .rmsc.multi-select {
  font-size: 14px;
  line-height: 17px;

  .select__menu {
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 10px;
    overflow: hidden;
  }

  .select__option {
    padding: 8px 16px;
    border-radius: 10px;

    &:hover {
      background-color: map-get($color-list, "black-5");
    }
  }

  .select__control, .dropdown-container {
    border-radius: 20px;
    min-height: 42px;
    box-shadow: none;

    &--is-focused, &:focus-within, &.focused {
      border-color: $main-active !important;
      box-shadow: none;
    }
  }

  .dropdown-heading {
    padding: 0 10px 0 16px;
    span {
      white-space: initial;
    }
  }

  .gray, .select__placeholder {
    color: map-get($color-list, "grey-50");
  }
  
  .dropdown-heading-value {
    color: map-get($color-list, "black-100");
  }

  // .select__value-container {
  //   padding-left: 16px;
  // }

  .select__indicator-separator {
    display: none;
  }

  .select__dropdown-indicator {
    padding-right: 16px;
  }

  &.initial {
    .select__single-value {
      color: rgba(101,111,141,0.5);
    }
  }

  &.invalid {
    .select__control {
      border-color: $main-red;
    } 
  }

  .dropdown-content {
    z-index: 2;

    .panel-content {
      border-radius: 20px;

      @media (min-width: 768px) {
        margin: 0 -45px;
      }

      @media (max-width: 991px) {
        min-width: 205px;
      }
    }

    li {
      width: 100%;
      padding: 0;

      .select-item {
        margin: 0;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background-color: map-get($color-list, "black-5");
        }

        label {
          margin-bottom: 0;
          margin-left: 10px;
        }

        div {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .dropdown-search-clear-icon, .clear-selected-button{
    &:focus {
      outline: none;
    }
  }

  .search {
    border: none;
    padding: 12px 16px;

    input {
      padding: 12px 16px;
      border-radius: 20px;
      color: map-get($color-list, "black-80");
      background: url("~assets/images/icons/search.svg") no-repeat scroll 7px 7px;
      background-color: map-get($color-list, "black-5");
      padding-left:30px;
    }

    .dropdown-search-clear-icon {
      color: map-get($color-list, "black-80");
      margin-right: 20px;

      &:focus {
        outline: none;
      }
    }

    button:focus {
      outline: none;
    }
  }

  .options {
    padding-bottom: 10px;
  }

  .owner__item {
    width: 85%;
    label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}