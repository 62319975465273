.plan-upgrade__info {
  padding: 20px;
}

.plan-upgrade__title {
  margin-bottom: 15px;
  text-align: left;
  color: $grey-1200;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;

  .fas {
    margin-right: 15px;
  }
}

.plan-upgrade__list-icon {
  margin-right: 5px;
}

.plan-upgrade__list-item {
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  padding-left: 0;
  padding-right: 0;
}

.plan-upgrade__text {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 14px;

  &.last {
    margin-bottom: 10px;
  }
}

.plan-upgrade__link {
  margin: 0 auto;
}