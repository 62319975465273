@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.candidates__list {
  margin: 0;

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(4, 90%);
    grid-gap: 10px;
  }

  .loading-next {
    height: 50px;
    width: 100% !important;
  }
}

.candidates__list-column-title {
  margin-bottom: 15px;
  @include font-size(12,20);
  text-transform: uppercase;
  color: $main-grey;
}

.candidates__list-counter {
  display: inline-block;
  margin-left: 10px;  
  padding: 0 12px;
  color: $main-white;
  border-radius: 10px;
  
  &.invited {
    color: $main-blue;
    background-color: rgba($main-blue, 0.2);
  }

  &.to_review {
    color: $main-purple;
    background-color: rgba($main-purple, 0.2);
  }

  &.accepted {
    color: $main-green;
    background-color: rgba($main-green, 0.2);
  }

  &.rejected {
    color: $main-red;
    background-color: rgba($main-red, 0.2);
  }
}
