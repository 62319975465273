@import '~assets/styles/abstracts/mixins';
@import '~assets/styles/abstracts/colors';

.popover {
  max-width: 450px !important;
  border-radius: 20px !important;
  border: none;
  box-shadow: 0 0 4px map-get($color-list, "grey-50");
}

.popover-body {
  padding: 20px !important;
  position: relative;
  text-align: center;

  h4 {
    margin-bottom: 16px;
    @include font-size(14, 20, 500);
    color: map-get($color-list, "black-80");
  }
  
  p {
    margin-bottom: 16px;
    @include font-size(14);
    color: map-get($color-list, "grey-100");
  
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    margin-left: 5px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.popover-icon {
  margin-bottom: 4px;
} 

.popover-close {
  @include font-size(16,16,500);
  color: map-get($color-list, "grey-50");
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}


