@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.progress {
  &__wrapper {
    width: 100%;
    margin-bottom: 35px;
  }

  &__pannel {
    margin-bottom: 40px;

    &.border-bottom {
      border-bottom: 1px solid $grey-1100;
    }

    .progress {
      height: 6px;
    }

    .progress-bar {
      background-color: $main-active; 
    }
  }
}

.progress__title {
  margin-bottom: 20px;

  h2 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;

    &.plan-title {
      @include align-center-center;
    }
  }

  span {
    margin-left: 10px;
    font-size: 16px;
    color: $green-400;

    svg {
      margin-left: 5px;
      width: 16px;
      height: 18px;
    }
  }
}

.progress__left-label, .progress__right-label {
  @include align-center;

  .progress__wrapper {
    margin-bottom: 10px;
  }
}

.progress__left-children-container {
  width: 100%;
  text-align: left;
  margin-bottom: -20px;
}

.progress__left-label-title, .progress__left-label-info {
  margin-right: 20px;
  font-size: 22px;
  line-height: 1;
  font-weight: 500;
  color: $grey-1100;
  padding-top: 20px;
}

.progress__left-label-info {
  margin-right: 0;
  margin-left: 10px;
}

.progress__total-title {
  margin-bottom: 20px;

  h2 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-align: left;
  }
}

.progress__footer {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  margin-bottom: 20px;

  p {
    margin-bottom: 5px;
  }

  span {
    margin-left: 5px;
    color: $blue-400; 
  }
}

.progress__labels {
  @include align-center-center;

  .left {
    font-size: 14px;
    line-height: 22px;
    color: $blue-400;
  }

  .right {
    font-size: 12px;
    line-height: 22px;
    color: $grey-400;
  }
}

.progress__moving-labels {
  @include align-center-center;
  .left {
    display: block;
    height: 20px;
    text-align: center;
    position: relative;
  }

  .left-icon {
    margin: 0 auto;
    position: absolute;
    top: -10px;
    right: 0;

    &.top {
      bottom: auto;
    }

    &.bottom {
      bottom: -30px;
    }
  }

  .left-text.danger {
    color: $main-red;
  }

  .left-text {
    margin: 0 auto;
    font-size: 12px;
    line-height: 22px;
    color: $main-active;
    position: absolute;
    right: 0;
    text-align: left;

    &.top {
      bottom: 0;
    }

    &.bottom {
      bottom: -30px;
    }
  }

  .right {
    font-size: 12px;
    line-height: 22px;
    color: $grey-400;
    position: relative;
  }

  .right-text {
    position: absolute;
    right: 37px;

    &.top {
      bottom: 0;
    }

    &.bottom {
      bottom: -37px;
    }
  }
}

.progress__pannel {
  &.records {
    position: relative;

    .progress__left-label-title, .progress__left-label-info {
      margin-top: 17px;
    }

    .progress__left-label {
      margin-bottom: 30px;
    }
  }
}
