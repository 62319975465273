@import '~assets/styles/abstracts/colors';
@import '~assets/styles/abstracts/mixins';

.company-card {
  margin-bottom: 30px;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(98, 110, 130, 0.5);

  &:hover {
    box-shadow: 0px 0px 10px rgba(98, 110, 130, 0.5);
  }
}

.company-card-header {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .company-default {
    color: rgba(90, 42, 241, 0.5);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    &-icon {
      background: rgba(90, 42, 241, 0.5);
      border-radius: 20px;
      margin-left: 5px;
      padding: 0 5px 1px;
    }
  }
}

.company-card-footer {
  font-size: 12px;
  line-height: 14px;
  color: $main-grey;
  text-transform: uppercase;
}

.company-card-icon {
  width: 16px;
  height: 16px;
}

.company-card-footer-btns {
  button {
    padding: 0 6px;
  }

  button:last-child {
    padding-right: 0;
  }
}

.company-jobs-number {
  margin-left: 5px;
  @include font-size(14, 16);
  font-weight: 600;
  color: $text-black;
}

.company-card-footer {
  @include align-center-center;
}